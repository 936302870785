import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      class="icon logout"
      height="1em"
      width="1em"
    >
      <path
        d="M17.9409 15.3714C17.7509 15.3714 17.5609 15.3014 17.4109 15.1514C17.1209 14.8614 17.1209 14.3814 17.4109 14.0914L19.4409 12.0614L17.4109 10.0314C17.1209 9.74141 17.1209 9.26141 17.4109 8.97141C17.7009 8.68141 18.1809 8.68141 18.4709 8.97141L21.0309 11.5314C21.3209 11.8214 21.3209 12.3014 21.0309 12.5914L18.4709 15.1514C18.3209 15.3014 18.1309 15.3714 17.9409 15.3714Z"
        fill="#F9FAFB"
      />
      <path
        d="M20.4298 12.8086H10.2598C9.84977 12.8086 9.50977 12.4686 9.50977 12.0586C9.50977 11.6486 9.84977 11.3086 10.2598 11.3086H20.4298C20.8398 11.3086 21.1798 11.6486 21.1798 12.0586C21.1798 12.4686 20.8398 12.8086 20.4298 12.8086Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.2598 20.75C7.10977 20.75 3.50977 17.15 3.50977 12C3.50977 6.85 7.10977 3.25 12.2598 3.25C12.6698 3.25 13.0098 3.59 13.0098 4C13.0098 4.41 12.6698 4.75 12.2598 4.75C7.98977 4.75 5.00977 7.73 5.00977 12C5.00977 16.27 7.98977 19.25 12.2598 19.25C12.6698 19.25 13.0098 19.59 13.0098 20C13.0098 20.41 12.6698 20.75 12.2598 20.75Z"
        fill="#F9FAFB"
      />
    </svg>
  );
}

export default LogoutIcon;
