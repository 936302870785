// import { configureStore } from "@reduxjs/toolkit";
// import { userReducer } from "./reducer/user";
// import { reportReducer } from "./reducer/report";
// import childReducer from './ChildSlice';
// import imageReducer from './ImageSlice';

import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import rootReducer from './reducer';
import {backend_url} from "../BaseUrl"

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;

// export const server = "http://localhost:8100/api/v1";
// export const server = "http://192.168.31.86:8100/api/v1";
export const server = backend_url;
console.log(server)

// export const server = "https://quantic.onrender.com/api/v1";