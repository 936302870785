import React, { useEffect, useState } from "react";
import "./Download.css";
import { MdClose } from "react-icons/md";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { TextField } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchPdfData, findReportDates } from "../../../../redux/action/report";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { backend_url } from "../../../../BaseUrl";
import { addDays, format, subMonths } from "date-fns";
function Download({
  downloadOpen,
  setDownloadOpen,
  curClientId,
  fetchPdfData,
  data,
  loading,
  location,
  violationType,
  status,
  tag,
}) {
  const dispatch = useDispatch();
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [dateRangeText, setDateRangeText] = useState("From - To");
  const selectedDate = useSelector((state) => state.child.selectedDate);
  const datesWithData = useSelector((state) => state.report.reportDates);
  const [disabledDates, setDisabledDates] = useState([]);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  const [calenderDate, setCalenderDate] = useState([
    {
      startDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      endDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      key: "selection",
    },
  ]);

  const generateDisabledDates = (enabledDates) => {
    const allDates = [];
    const currentDate = new Date();
    const startDate = subMonths(currentDate, 24); // Start date 24 months before the current date
    const endDate = addDays(currentDate, 720); // End date 1 year after the current date

    let dateToAdd = new Date(startDate);

    // Loop through the range of dates and check if it's not in enabledDates, then add it to disabledDates array
    while (dateToAdd <= endDate) {
      const dateString = format(dateToAdd, "yyyy-MM-dd");
      if (!enabledDates.includes(dateString)) {
        allDates.push(new Date(dateString));
      }
      dateToAdd = addDays(dateToAdd, 1); // Move to the next day
    }

    return allDates;
  };

  // Update disabled dates when datesWithData changes
  useEffect(() => {
    if (datesWithData && datesWithData.length > 0) {
      const enabledDates = datesWithData.map((date) => new Date(date));
      const allDates = generateDisabledDates(enabledDates);
      const disabledDatesArray = allDates.filter(
        (date) => !datesWithData.includes(format(date, "yyyy-MM-dd"))
      );
      setDisabledDates(disabledDatesArray);
    }
  }, [datesWithData]);

  console.log(disabledDates);
  useEffect(() => {
    dispatch(findReportDates(curClientId));
  }, [dispatch]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  console.log(datesWithData);
  const handleCloseDownload = () => {
    setDownloadOpen(!downloadOpen);
  };

  const handleCalendarClick = () => {
    setCalendarVisible(!calendarVisible);
  };

  useEffect(() => {
    if (calenderDate.length > 0) {
      const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
      const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
      setDateRangeText(`${fromText} - ${toText}`);
    } else {
      setDateRangeText("From - To");
    }
  }, [calenderDate]);

  const downloadPDF = async () => {
    // Wait until loading is finished, if applicable
    while (loading) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the timeout as needed
    }

    // Format the selected dates
    const startDateParam = dayjs(calenderDate[0]?.startDate).format(
      "YYYY-MM-DD"
    );
    const endDateParam = dayjs(calenderDate[0]?.endDate).format("YYYY-MM-DD");

    // Get the selected start and end times
    const startTimeParam = fromTime?.format("HH:mm:ss") || "00:00:00";
    const endTimeParam = toTime?.format("HH:mm:ss") || "23:59:59";

    // Check if the start date is in the datesWithData array
    const isStartDateValid = datesWithData.includes(startDateParam);

    // Enable or disable the download button based on whether the start date is in the datesWithData array
    const isDownloadDisabled = !isStartDateValid;

    // If download is disabled, log a message and exit the function
    if (isDownloadDisabled) {
      console.log(
        "Start date is not in datesWithData array. Download button disabled."
      );
      return;
    }

    // Construct the download URL
    let url = `${backend_url}/report/download-pdf?`;
    if (startDateParam) {
      url += `&startDate=${encodeURIComponent(startDateParam)}`;
    }
    if (endDateParam) {
      url += `&endDate=${encodeURIComponent(endDateParam)}`;
    }
    if (startTimeParam) {
      url += `&startTime=${encodeURIComponent(startTimeParam)}`;
    }
    if (endTimeParam) {
      url += `&endTime=${encodeURIComponent(endTimeParam)}`;
    }
    if (curClientId) {
      url += `&clientId=${encodeURIComponent(curClientId)}`;
    }
    if (
      location &&
      location !== null &&
      location !== "" &&
      location !== "ALL"
    ) {
      url += `&location=${encodeURIComponent(location)}`;
    }
    if (status && status !== null && status !== "" && status !== "ALL") {
      url += `&status=${encodeURIComponent(status)}`;
    }
    if (
      violationType &&
      violationType !== null &&
      violationType !== "" &&
      violationType !== "ALL"
    ) {
      url += `&violationType=${encodeURIComponent(violationType)}`;
    }
    if (tag && tag !== null && tag !== "" && tag !== "ALL") {
      url += `&tag=${encodeURIComponent(tag)}`;
    }

    // Remove the initial '&' if any parameters were appended
    url = url.replace("?&", "?");

    // Navigate to the download link in the same page
    window.location.href = url;
  };

  return (
    <div className="download-container">
      <div className="download-header">
        <span>Download</span>
        <MdClose className="popup-close-button" onClick={handleCloseDownload} />
      </div>
      <div className="download-margin-container">
        <div className="download-content">
          <div className="date-container">
            <label>Date</label>
            <div
              className="download-calender-container"
              onClick={handleCalendarClick}
            >
              <span>{dateRangeText}</span>
              <MdOutlineCalendarMonth />
            </div>
          </div>
          <div className="time-container">
            <label className="time-header-label">Time</label>
            <div className="from-to-container">
              <div className="from-container time-in">
                <label>From</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={fromTime}
                    onChange={(newFromTime) => {
                      setFromTime(newFromTime);
                    }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="to-container time-in">
                <label>To</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={toTime}
                    onChange={(newToTime) => {
                      setToTime(newToTime);
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        InputProps={{
                          endAdornment: <AiOutlineClockCircle />,
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        {calendarVisible && (
          <div className="calender-div">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setCalenderDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={calenderDate}
              endDatePlaceholder={new Date()}
              disabledDates={disabledDates}
            />
          </div>
        )}
      </div>
      <div className="download-footer">
        <div className="footer-btn-container">
          <button className="download-cancel-btn" onClick={handleCloseDownload}>
            Cancel
          </button>
          <button
            className="download-download-btn"
            onClick={downloadPDF}
            disabled={
              !datesWithData?.includes(
                dayjs(calenderDate[0]?.startDate).format("YYYY-MM-DD")
              )
            }
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.pdf.pdfData,
  loading: state.pdf.loading,
});

const mapDispatchToProps = {
  fetchPdfData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
