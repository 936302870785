// import {
//   fetchReportDataRequest,
//   fetchReportDataSuccess,
//   fetchReportDataFailure
// } from '../action/report';

// const report = {
//   data: [],
//   loading: false,
//   error: null,
// };

// const reportReducer = (state = report, action) => {
//   switch (action.type) {
//     case fetchReportDataRequest().type:
//       return { ...state, loading: true, error: null };
//     case fetchReportDataSuccess().type:
//       return { ...state, loading: false, data: action.payload };
//     case fetchReportDataFailure().type:
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// export default reportReducer;

import { createReducer } from "@reduxjs/toolkit";

const reportReducer = createReducer(
  {},
  {
    reportfetchRequest: (state) => {
      state.loading = true;
    },
    reportfetchSuccess: (state, action) => {
      state.loading = false;
      state.allReports = action.payload.reports;
      state.message = action.payload.message;
    },
    reportfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    tagfetchRequest: (state) => {
      state.loading = true;
    },
    tagfetchSuccess: (state, action) => {
      state.loading = false;
      state.allTags = action.payload.tags;
      state.message = action.payload?.message;
    },
    tagfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    locationfetchRequest: (state) => {
      state.loading = true;
    },
    locationfetchSuccess: (state, action) => {
      state.loading = false;
      state.allLocations = action.payload.locations;
      state.message = action.payload.message;
    },
    locationfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    statusfetchRequest: (state) => {
      state.loading = true;
    },
    statusfetchSuccess: (state, action) => {
      state.loading = false;
      state.allStatus = action.payload.status;
      state.message = action.payload.message;
    },
    statusfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    violationTypefetchRequest: (state) => {
      state.loading = true;
    },
    violationTypefetchSuccess: (state, action) => {
      state.loading = false;
      state.allViolationType = action.payload.violations;
      state.message = action.payload.message;
    },
    violationTypefetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addCommentRequest: (state) => {
      state.loading = true;
    },
    addCommentSuccess: (state, action) => {
      state.loading = false;
      state.allReports = action.payload.reports;
      state.message = action.payload.message;
    },
    addCommentFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = false;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    notificationfetchRequest: (state) => {
      state.loading = true;
    },
    notificationfetchSuccess: (state, action) => {
      state.loading = false;
      state.allNotifications = action.payload.notification;
      state.message = action.payload?.message;
    },
    notificationfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    notificationStatusChangeRequest: (state) => {
      state.loading = true;
    },
    notificationStatusChangeSuccess: (state, action) => {
      state.loading = false;
      state.allNotifications = action.payload.notification;
      state.message = action.payload?.message;
    },
    notificationStatusChangeFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    ticketfetchRequest: (state) => {
      state.loading = true;
    },
    ticketfetchSuccess: (state, action) => {
      state.loading = false;
      state.alltickets = action.payload.tickets;
      state.message = action.payload?.message;
    },
    ticketfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addTicketRequest: (state) => {
      state.loading = true;
    },
    addTicketSuccess: (state, action) => {
      state.loading = false;
      state.alltickets = action.payload.ticket;
      state.message = action.payload.message;
    },
    addTicketFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    pdffetchRequest: (state) => {
      state.loading = true;
    },
    pdffetchSuccess: (state, action) => {
      state.loading = false;
      state.allpdfs = action.payload.pdf;
      state.message = action.payload?.message;
    },
    pdffetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    reportDatesFetchRequest: (state) => {
      state.loading = true;
    },
    reportDatesFetchSuccess: (state, action) => {
      state.loading = false;
      state.reportDates = action.payload;
      state.message = action.payload.message;
    },
    reportDatesFetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
);

export default reportReducer;
