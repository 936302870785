import React, { useEffect, useState } from "react";
import "./Filter.css";
import { MdClose } from "react-icons/md";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { server } from "../../../redux/store";
import { fetchTickets } from "../../../redux/action/ticket";
import { updateTicketsFilter } from "../../../redux/ChildSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
const convertToIndianTime = (time) => {
  // Convert time to Indian Standard Time (IST) and format it
  return dayjs(time).locale("en-IN").format("ddd, DD MMM YYYY, HH:mm:ss");
};
function Filter({ filterOpen, setFilterOpen }) {
  const {
    message,
    error,
    allReports,
    allTags,
    allLocations,
    allStatus,
    allViolationType,
    loading,
    alltickets,
  } = useSelector((state) => state.report);
  // const { allpdfs, loading } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // useEffect(() => {
  //   dispatch(fetchTickets());
  // }, [alltickets?.length]);

  const renderValueStyle = {
    // height: isSmallScreen ? '20px' : 'px',
    padding: isSmallScreen ? "2px 4px" : "12px 16px",
  };
  const typographyStyle = {
    // height: isSmallScreen ? '20px' : 'px',
    lineHeight: isSmallScreen ? "10px" : "20px",
    fontSize: isSmallScreen ? "8px" : "14px",
  };
  const labelTypographyStyle = {
    marginTop: isSmallScreen ? "2px" : "12px",
  };
  const outlineInputStyle = {
    padding: isSmallScreen ? "8px" : "12px",
  };

  const menuItemStyle = {
    minHeight: isSmallScreen ? "40px" : "48px",
    padding: isSmallScreen ? "8px 12px" : "12px 16px",
  };
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [dateRangeText, setDateRangeText] = useState("From - To");
  const [calenderDate, setCalenderDate] = useState(() => {
    // Retrieve calenderDate from sessionStorage if it exists, else set default
    const storedCalenderDate = sessionStorage.getItem("calenderDate");
    return storedCalenderDate
      ? JSON.parse(storedCalenderDate)
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ];
  });
  const [updatedCalenderDate, setUpdatedCalenderDate] = useState(null);

  // Remove duplicate entries from alltickets array and create a Set to ensure uniqueness
  const uniqueStatusSet = new Set(alltickets.map((obj) => obj.status));

  // Convert the Set back to an array and add "All" as the first element
  const status = ["All", ...Array.from(uniqueStatusSet)];

  // Add "All" as the first element in the reason array
  const reason = ["All", "Software", "Hardware"];
  const [defaultStatus, setDefaultStatus] = useState(() => {
    const storedCurrentStatus = sessionStorage.getItem("statusTicketCurrent");
    return storedCurrentStatus;
  });
  const [defaultReason, setDefaultReason] = useState(() => {
    const storedDefaultReason = sessionStorage.getItem("reasonCurrent");
    return storedDefaultReason;
  });
  // useEffect(()=>{
  //   if(updatedCalenderDate!==null && updatedCalenderDate.length>0)
  //   setCalenderDate(updatedCalenderDate);
  // },[updatedCalenderDate]);
  // console.log(updatedCalenderDate)
  console.log(calenderDate);

  useEffect(() => {
    sessionStorage.setItem("calenderDate", JSON.stringify(calenderDate));
  }, [calenderDate]);

  const [date, setDate] = useState([
    new Date(2021, 6, 1),
    new Date(2021, 6, 10),
  ]);
  useEffect(() => {
    // setDefaultStatus([status[0]]);
  }, [status]);
  // useEffect(() => {
  //   dispatch(fetchDownloadPdf());
  // }, [allpdfs?.length]);
  const [calendarVisible, setCalendarVisible] = useState(false); // New state

  const handleCloseFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleCalendarClick = () => {
    setCalendarVisible(!calendarVisible);
  };

  useEffect(() => {
    console.log(
      "fromTime:",
      fromTime,
      "toTime:",
      toTime,
      "calenderDate:",
      calenderDate
    );
  }, [fromTime, toTime, calenderDate]);

  useEffect(() => {
    // Update the span text when calenderDate changes
    if (calenderDate.length > 0) {
      const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
      const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
      setDateRangeText(`${fromText} - ${toText}`);
    } else {
      setDateRangeText("From - To");
    }
  }, [calenderDate]);
  console.log(dateRangeText);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [statusTicketCurrent, setStatusTicketCurrent] = useState(() => {
    const storedCurrentStatus = sessionStorage.getItem("statusTicketCurrent");
    return storedCurrentStatus ? storedCurrentStatus : "";
  });
  const [reasonCurrent, setReasonCurrent] = useState(() => {
    const storedReasonCurrent = sessionStorage.getItem("reasonCurrent");
    return storedReasonCurrent ? storedReasonCurrent : "";
  });
  function getStyles(status, statusTicketCurrent, theme) {
    return {
      fontWeight:
        statusTicketCurrent.indexOf(status) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatusTicketCurrent(value); // Set status to empty string if "All" is selected
    sessionStorage.setItem("statusTicketCurrent", value);
  };
  const handleReasonChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = value === "" ? "All" : value;

    setReasonCurrent(newValue);
    sessionStorage.setItem("reasonCurrent", newValue);
  };
  const saveHandler = async () => {
    try {
      //earlier edit reports was done directly from savehandler but since the findReports need to be called again to ensure that only filtered reports are displayed the editReports was done in index.js itself
      // await dispatch(editReport(dummyReport._id, statusCurrent, tagCurrent, assignCurrent));
      dispatch(
        updateTicketsFilter({
          statusTicketCurrent,
          fromTime,
          toTime,
          calenderDate,
          reasonCurrent,
        })
      );
      setFilterOpen(false);
    } catch (error) {
      // Handle errors
      console.error("Error saving report:", error);
    }
  };
  console.log(statusTicketCurrent, fromTime, toTime, calenderDate);
  console.log(status[0]);
  return (
    // <div className="download-main-container">
    <div className="download-container">
      <div className="download-header">
        <span>Filter</span>
        <MdClose className="popup-close-button" onClick={handleCloseFilter} />
      </div>
      <div className="download-margin-container">
        <div className="download-content">
          <div className="date-container">
            <label>Date</label>
            <div
              className={`support-filter-calender-container `}
              onClick={handleCalendarClick}
            >
              <span>{dateRangeText}</span>
              <MdOutlineCalendarMonth />
            </div>
          </div>
          <div className="time-container">
            <label className="time-header-label">Time</label>
            <div className="from-to-container">
              <div className="from-container time-in">
                <label>From</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={fromTime}
                    onChange={(newFromTime) => {
                      setFromTime(newFromTime);
                      sessionStorage.setItem(
                        "fromTime",
                        convertToIndianTime(newFromTime)
                      );
                    }}
                    renderInput={(props) => (
                      <TextField {...props} style={{ ...renderValueStyle }} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="to-container time-in">
                <label>To</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={toTime}
                    onChange={(newToTime) => {
                      setToTime(newToTime);
                      sessionStorage.setItem(
                        "toTime",
                        convertToIndianTime(newToTime)
                      );
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        InputProps={{
                          endAdornment: <AiOutlineClockCircle />,
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="support-dropdown-style">
              <Typography
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  // marginTop: "12px",
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "rgb(69, 79, 91)",
                  ...labelTypographyStyle,
                }}
              >
                Reason
              </Typography>

              <FormControl sx={{ width: "100%", padding: 0 }}>
                {/* <InputLabel id="reason">Reason</InputLabel> */}
                <Select
                  value={reasonCurrent}
                  defaultValue={defaultReason}
                  displayEmpty //for displaying placeholder
                  placeholder="Reason"
                  onChange={handleReasonChange}
                  style={{ padding: "0.5rem" }}
                  // input={<OutlinedInput id="status" label="Chip" style={{...outlineInputStyle}} />}
                  renderValue={(selected) => {
                    if (!selected || selected === "" || selected.length === 0) {
                      return <em>Reason</em>;
                    }
                    return (
                      <div style={{ display: "inline-block" }}>
                        <Box
                          style={{
                            height: "30px",
                            padding: "4px 0px",
                            borderRadius: "2px",
                            width: "auto",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "PlusJakartaSans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              ...typographyStyle,
                            }}
                          >
                            {selected.charAt(0).toUpperCase() +
                              selected.slice(1).toLowerCase()}
                          </Typography>
                        </Box>
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {reason &&
                    reason.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        defaultValue={defaultReason}
                        style={getStyles(name, reasonCurrent, theme)}
                      >
                        {name.charAt(0).toUpperCase() +
                          name.slice(1).toLowerCase()}{" "}
                        {/* Change first letter to uppercase */}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="support-dropdown-style">
              <Typography
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  // marginTop: "12px",
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "rgb(69, 79, 91)",
                  ...labelTypographyStyle,
                }}
              >
                Status
              </Typography>

              <FormControl sx={{ width: "100%", padding: 0 }}>
                {/* <InputLabel id="status">Status</InputLabel> */}
                <Select
                  value={statusTicketCurrent}
                  defaultValue={defaultStatus}
                  onChange={handleStatusChange}
                  displayEmpty //for displaying placeholder
                  style={{ padding: "0.5rem" }}
                  // input={
                  //   <OutlinedInput
                  //     id="status"
                  //     label="Chip"
                  //     style={{ ...outlineInputStyle }}
                  //   />
                  // }
                  renderValue={(selected) => {
                    if (!selected || selected === "" || selected.length === 0) {
                      return <em>Status</em>;
                    }
                    return (
                      <div
                        // className="support-filter-select"
                        style={{ display: "inline-block" }}
                      >
                        <Box
                          height={30}
                          padding={selected === "All" ? "4px 0px" : "4px 8px"}
                          borderRadius={1}
                          bgcolor={
                            selected === "open"
                              ? "#FFF3D6"
                              : selected === "resolved"
                              ? "#F3FFF6"
                              : selected === "in progress"
                              ? "#E0F1F5"
                              : ""
                          }
                          width="auto"
                        >
                          <Typography
                            style={{
                              fontFamily: "PlusJakartaSans",
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "20px",
                              color:
                                selected === "open"
                                  ? "#694100"
                                  : selected === "resolved"
                                  ? "#006100"
                                  : selected === "in progress"
                                  ? "#005278"
                                  : "",
                            }}
                          >
                            {selected.toUpperCase()}
                          </Typography>
                        </Box>
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {status &&
                    status.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        defaultValue={defaultStatus}
                        style={getStyles(name, statusTicketCurrent, theme)}
                      >
                        {name.toUpperCase()}{" "}
                        {/* Change first letter to uppercase */}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {calendarVisible && (
          <div className="calender-div">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setCalenderDate([item.selection]);
                setUpdatedCalenderDate([item.selection]);
              }}
              moveRangeOnFirstSelection={true}
              ranges={calenderDate}
              endDatePlaceholder={new Date()}
            />
          </div>
        )}
      </div>
      <div className="download-footer">
        <div className="footer-btn-container">
          <button className="download-cancel-btn" onClick={handleCloseFilter}>
            Cancel
          </button>
          <button className="download-download-btn" onClick={saveHandler}>
            Save
          </button>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Filter;
