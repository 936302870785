import {
  fetchPdfDataRequest,
  fetchPdfDataSuccess,
  fetchPdfDataFailure,
} from "../action/report";

const initialState = {
  pdfData: [],
  loading: false,
  error: null,
};

const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchPdfDataRequest().type:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case fetchPdfDataSuccess().type:
      return {
        ...state,
        pdfData: action.payload,
        loading: false,
        error: null,
      };
    case fetchPdfDataFailure().type:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default pdfReducer;
