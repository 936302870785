import React from 'react'
import LeftNav from '../../components/LeftNav/LeftNav'
import TopBar from '../../components/TopBar/TopBar'
import AnalyticsPage from '.'
const Analytics = () => {
  return (
    <div>
    <div className='dashboard-main'>
    <div className='sidebarCss'>
    <LeftNav/></div>
    <div className='mainCss'>
    <TopBar/>
    <AnalyticsPage/>
    </div>
    </div>
    </div>
  )
}

export default Analytics
