import { createReducer } from "@reduxjs/toolkit";

const ticketReducer = createReducer(
  {},
  {
    addTicketRequest: (state) => {
      state.loading = true;
    },
    addTicketSuccess: (state, action) => {
      state.loading = false;
      state.ticket = action.payload.ticket;
      state.message = action.payload.message;
    },
    addTicketFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    ticketfetchRequest: (state) => {
      state.loading = true;
    },
    ticketfetchSuccess: (state, action) => {
      state.loading = false;
      state.alltickets = action.payload.tickets;
      state.message = action.payload?.message;
    },
    ticketfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addSupportCommentRequest: (state) => {
      state.loading = true;
    },
    addSupportCommentSuccess: (state, action) => {
      state.loading = false;
      state.alltickets = action.payload.ticket;
      state.message = action.payload.message;
    },
    addSupportCommentFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    filefetchRequest: (state) => {
      state.loading = true;
    },
    filefetchSuccess: (state, action) => {
      state.loading = false;
      state.allfiles = action.payload.files;
      state.message = action.payload?.message;
    },
    filefetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addSupportFileRequest: (state) => {
      state.loading = true;
    },
    addSupportFileSuccess: (state, action) => {
      state.loading = false;
      state.alltickets = action.payload.ticket;
      state.message = action.payload.message;
    },
    addSupportFileFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fileDownloadRequest: (state) => {
      state.loading = true;
    },
    fileDownloadSuccess: (state, action) => {
      state.loading = false;
      state.file = action.payload.file;
      state.message = action.payload?.message;
    },
    fileDownloadFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
);

export default ticketReducer;
