import React from "react";

function OverviewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      class="icon Analytics"
      height="1em"
      width="1em"
    >
      <path
        d="M15.75 22.5H7.75C4.1 22.5 2 20.4 2 16.75V6.75C2 3.1 4.1 1 7.75 1H15.75C19.4 1 21.5 3.1 21.5 6.75V16.75C17.3802 16.6556 15.1775 18.6397 15.75 22.5ZM7.75 2.5C4.89 2.5 3.5 3.89 3.5 6.75V16.75C3.5 19.61 4.89 21 7.75 21H15.75C15.7596 18.5865 17.2029 17.2985 20 16.75V6.75C20 3.89 18.61 2.5 15.75 2.5H7.75Z"
        fill="#F9FAFB"
      />
      <path
        d="M18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
        fill="#F9FAFB"
      />
      <path
        d="M11.75 11.5H7.75C7.34 11.5 7 11.16 7 10.75C7 10.34 7.34 10 7.75 10H11.75C12.16 10 12.5 10.34 12.5 10.75C12.5 11.16 12.16 11.5 11.75 11.5Z"
        fill="#F9FAFB"
      />
      <path
        d="M13.75 15.75H8C7.59 15.75 7.25 15.41 7.25 15C7.25 14.59 7.59 14.25 8 14.25H13.75C14.16 14.25 14.5 14.59 14.5 15C14.5 15.41 14.16 15.75 13.75 15.75Z"
        fill="#F9FAFB"
      />
      <path
        d="M18.4147 23.8295C15.9783 23.8295 14 21.8512 14 19.4147C14 16.9783 15.9783 15 18.4147 15C20.8512 15 22.8295 16.9783 22.8295 19.4147C22.8295 21.8512 20.8512 23.8295 18.4147 23.8295ZM18.4147 16.6765C16.9059 16.6765 15.6765 17.9059 15.6765 19.4147C15.6765 20.9236 16.9059 22.153 18.4147 22.153C19.9236 22.153 21.153 20.9236 21.153 19.4147C21.153 17.9059 19.9236 16.6765 18.4147 16.6765Z"
        fill="#F9FAFB"
      />
      <path
        d="M22.9531 24.7913C22.7407 24.7913 22.5284 24.7131 22.3607 24.5455L21.2431 23.4278C20.919 23.1037 20.919 22.5672 21.2431 22.2431C21.5672 21.919 22.1037 21.919 22.4278 22.2431L23.5455 23.3607C23.8696 23.6849 23.8696 24.2213 23.5455 24.5455C23.3778 24.7131 23.1655 24.7913 22.9531 24.7913Z"
        fill="#F9FAFB"
      />
    </svg>
  );
}

export default OverviewIcon;
