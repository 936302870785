import {
    fetchRecentReportDataRequest,
    fetchRecentReportDataSuccess,
    fetchRecentReportDataFailure
  } from '../action/report';

const recentReport = {
    data: [],
    loading: false,
    error: null,
  };
  
  
  const recentReportReducer = (state = recentReport, action) => {
    switch (action.type) {
      case fetchRecentReportDataRequest().type:
        return { ...state, loading: true, error: null };
      case fetchRecentReportDataSuccess().type:
        return { ...state, loading: false, data: action.payload };
      case fetchRecentReportDataFailure().type:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default recentReportReducer;