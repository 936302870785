import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateExpandImageUrl } from "../../../redux/ChildSlice";
import Popup from "reactjs-popup";
import "./ReportImageExpand.css";
import { MdClose } from "react-icons/md";

function ReportCardImageExpand({ imgOpen, handleCloseImg }) {
  const dispatch = useDispatch();

  const expandImgUrl = useSelector((state) => state.child.expandImgUrl);
  const expandImgId = useSelector((state) => state.child.expandImgId);
  console.log("expandImgUrl", expandImgUrl, expandImgId);

  // const handleCloseImg = () => {
  //   setImgOpen(!imgOpen);
  // };

  return (
    <Popup
      id="image-expand-popup"
      className="image-expand-popup"
      open={imgOpen}
      closeOnDocumentClick
      onClose={handleCloseImg}
      modal
      nested
    >
      {(close) => (
        <div className="expand-img-container">
          <div className="expand-img-title">
            #{expandImgId}
            <MdClose
              className="popup-close-button"
              onClick={() => {
                handleCloseImg();
                close();
              }}
            />
          </div>

          <div className="expand-img-src-container">
            {expandImgUrl ? (
              <img
                className="expand-img-src-container"
                src={expandImgUrl}
                alt="Expanded"
              />
            ) : (
              <p>No image to display</p>
            )}
          </div>
        </div>
      )}
    </Popup>
  );
}

export default ReportCardImageExpand;
