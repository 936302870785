import React, { useEffect, useRef, useState } from "react";
import "./Comments.css";
import { MdClose } from "react-icons/md";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import { updateSupportComments } from "../../../redux/ChildSlice";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  addSupportComment,
  addSupportCommentFiles,
  addSupportFiles,
  fetchSupportFiles,
  fetchTickets,
} from "../../../redux/action/ticket";
import dummyPdf from "../Images/dummyPdf.png";
import dummyDoc from "../Images/dummyDocument.png";
import pdfPreview from "../Images/dummyPdfPreview.png";
import docPreview from "../Images/dummyDocumentPreview.png";
function SupportComments({
  commentsOpen,
  setCommentsOpen,
  curClientId,
  curUser,
  curUserId,
  curTicketId,
  comments,
}) {
  const dispatch = useDispatch();
  const [supportComment, setSupportComment] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [ticketUploadFiles, setTicketUploadFiles] = useState(null);
  const [fileNames, setFileNames] = useState(null);
  const bottomRef = useRef(null);
  const files = useSelector((state) => state.ticket.allfiles);
  console.log(uploadFiles);
  const handleCloseComments = () => {
    setCommentsOpen(!commentsOpen);
  };
  // useEffect(()=>{
  //   dispatch(fetchSupportFiles(curClientId,curClientId));
  // },[])

  const scrollToBottom = () => {
    bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
  };

  useEffect(() => {
    // Scroll to the bottom when component mounts
    setTimeout(() => {
      if (bottomRef.current !== null) {
        bottomRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "end",
        });
      }
    }, 1000); // Adjust the delay as needed
  }, []);

  const postButtonHandler = async () => {
    try {
      if (
        ((supportComment !== null && supportComment !== "") ||
          ticketUploadFiles !== null) &&
        curClientId !== null &&
        curTicketId !== "" &&
        curUser !== ""
      ) {
        const newFilesToUpload = ticketUploadFiles?.filter(
          (file) => !files.includes(file.name)
        );
        await dispatch(
          addSupportComment(
            supportComment,
            curUserId,
            curTicketId,
            curUser,
            fileNames,
            curClientId
          )
        );
        console.log("new files", newFilesToUpload);

        if (newFilesToUpload && newFilesToUpload.length > 0) {
          await dispatch(
            addSupportFiles(
              curTicketId,
              curClientId,
              newFilesToUpload.map((file) => file.name)
            )
          );
          await dispatch(
            addSupportCommentFiles(newFilesToUpload, curClientId, curTicketId)
          );
        }

        await dispatch(fetchTickets());

        setSupportComment("");
        setUploadFiles([]);
        setCommentsOpen(false);
        setTicketUploadFiles(null);
      }
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };

  const handleCommentsChange = (event) => {
    setSupportComment(event.target.value);
  };
  useEffect(() => {
    console.log("supportComment", supportComment);
  }, [supportComment]);
  console.log(curUser);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    console.log(files);
    const filesToUpload = Array.from(files).slice(0, 2); // Limiting to the first two files
    const fileNames = filesToUpload.map((file) => file.name);
    setFileNames(fileNames);

    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("file", file);
    });

    setTicketUploadFiles(filesToUpload);

    // Read the selected files and update uploadImages state
    const updatedFiles = [];
    for (let i = 0; i < Math.min(filesToUpload.length, 2); i++) {
      const reader = new FileReader();
      reader.onload = function (e) {
        updatedFiles.push({
          dataUrl: e.target.result,
          fileName: filesToUpload[i].name,
        });
        if (updatedFiles.length === Math.min(filesToUpload.length, 2)) {
          setUploadFiles(updatedFiles);
        }
      };
      reader.readAsDataURL(filesToUpload[i]);
    }
  };
  // useEffect(()=>{
  //   if(ticketUploadFiles!==null){
  //     console.log(ticketUploadFiles)
  //    dispatch(addSupportCommentFiles(ticketUploadFiles,curClientId,curTicketId));
  //   }

  // },[curClientId, curTicketId, dispatch, ticketUploadFiles])
  // console.log("files", ticketUploadFiles);
  // console.log("files", uploadFiles);
  const handleRemoveFile = (indexToRemove) => {
    setUploadFiles(uploadFiles.filter((_, index) => index !== indexToRemove));
    setTicketUploadFiles(
      ticketUploadFiles.filter((_, index) => index !== indexToRemove)
    );
  };
  console.log(
    "uploadFiles",
    uploadFiles,
    "ticketUploadFiles",
    ticketUploadFiles
  );
  return (
    <div className="download-container">
      <div className="download-header">
        <span>{`ID:#${curTicketId.slice(-7)}`}</span>
        <MdClose className="popup-close-button" onClick={handleCloseComments} />
      </div>
      <div className="support-margin-container">
        <div className="support-comments-content" ref={bottomRef}>
          {comments &&
            comments.length > 0 &&
            comments.map((comment, index) => (
              <div
                className={`${
                  curUserId === comment.userId
                    ? "each-comment-user"
                    : "each-comment-reply"
                }`}
                key={index}
              >
                <div
                  className={`${
                    curUserId === comment.userId
                      ? "comments-user-comment"
                      : "comments-reply-comment"
                  } `}
                >
                  <div
                    className={`${
                      curUserId === comment.userId
                        ? "support-each-comment-user-icon-container"
                        : "support-each-comment-reply-icon-container"
                    }`}
                  >
                    <div
                      className={`${
                        curUserId === comment.userId
                          ? "support-each-comments-user"
                          : "support-each-comments-reply"
                      }`}
                    >
                      {comment && comment.name && comment.name.charAt(0)}
                    </div>
                  </div>
                  <div
                    className={`${
                      (comment.message !== "" && comment.file.length > 0) ||
                      comment.file.length >= 2
                        ? "multi-line"
                        : ""
                    }`}
                  >
                    {comment.message && (
                      <div
                        className={`${
                          curUserId === comment.userId
                            ? "each-comment-content"
                            : "each-comment-reply-content"
                        } ${
                          (comment.message !== "" && comment.file.length > 0) ||
                          comment.file.length >= 2
                            ? "multi-line"
                            : ""
                        }`}
                      >
                        <p className="comment">{comment.message}</p>
                      </div>
                    )}

                    {comment.file &&
                      comment.file.length > 0 &&
                      comment.file.map((file, index) => (
                        <div
                          className={`${
                            curUserId === comment.userId
                              ? "each-comment-content"
                              : "each-comment-reply-content"
                          } ${
                            (comment.message !== "" &&
                              comment.file.length > 0) ||
                            comment.file.length >= 2
                              ? "multi-line"
                              : ""
                          }`}
                          key={index}
                        >
                          <div className="file-preview-container">
                            <img
                              className="preview-image"
                              src={
                                file.filename.includes(".pdf")
                                  ? dummyPdf
                                  : file.filename.includes(".doc") ||
                                    file.filename.includes(".docx")
                                  ? dummyDoc
                                  : `https://cv-safety.s3.ap-south-1.amazonaws.com/uploads/${file.url}`
                              }
                              alt={`Preview ${file.filename}`}
                            />
                            <span className="preview-filename">
                              {file.filename}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="each-comment-reply">
            <div className="comments-reply-comment">
              <div className="support-each-comment-user-icon-container">
                <div className="support-each-comments-reply">
                  {curUser.charAt(0)}
                </div>
              </div>
              <div className="each-comment-reply-content">
                <p className="comment">
                  Hi John, Sure it can be done. To have more clarity on the ROI,
                  our team will connect with you within an hour Ok Tq
                </p>
              </div>
              
            </div>  
          </div> */}
        </div>
      </div>
      <div className="comments-footer-container">
        <div className="upload-files-container">
          <div className="uploaded-file-container" id="uploadedFileContainer">
            {/* Render uploaded Files */}
            {uploadFiles.map((fileData, index) => {
              const { dataUrl, fileName } = fileData;
              let backgroundImageUrl;
              const mimeType = dataUrl
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
              if (mimeType === "application/pdf") {
                backgroundImageUrl = `url(${pdfPreview})`; // Set background to dummyPdf for PDF files
              } else if (
                mimeType === "application/msword" ||
                mimeType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                mimeType === "application/wps-office.doc"
              ) {
                backgroundImageUrl = `url(${docPreview})`; // Set background to dummyDoc for DOC/DOCX files
              } else {
                backgroundImageUrl = `url(${dataUrl})`; // Use the data URL for other file types
              }

              return (
                <div
                  className="uploaded-image"
                  key={index}
                  style={{
                    backgroundImage: backgroundImageUrl,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="uploaded-image-close"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <MdClose />
                  </div>
                  <span className="file-preview-title">{fileName}</span>{" "}
                  {/* Display the file name */}
                </div>
              );
            })}
            <label for="fileInput" class="upload-button">
              <input
                type="file"
                id="fileInput"
                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
              <div class="square-box">
                <div class="plus-icon">+</div>
              </div>
            </label>
          </div>
        </div>
        <div className="comments-footer">
          <div className="support-footer-btn-container">
            <div className="support-comments-user">{curUser.charAt(0)}</div>
            <input
              className="support-comments-input"
              type="text"
              value={supportComment}
              onChange={handleCommentsChange}
              placeholder="Write your comments"
            ></input>
            <button
              className="download-download-btn"
              onClick={postButtonHandler}
            >
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportComments;
