import React, { useEffect, useState } from "react";
import "./Calender.css";
import { MdClose } from "react-icons/md";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import {
  updateSelectedDate,
  updateSelectedDateFormatted,
} from "../../../../redux/ChildSlice";
function Calender({ calenderOpen, setCalenderOpen, date, setDate }) {
  const [selectedDate, setSelectedDate] = useState(date);
  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleSave = () => {
    const formattedDate = formatDateString(selectedDate);
    setDate(selectedDate);
    dispatch(updateSelectedDate(formattedDate));
    dispatch(updateSelectedDateFormatted(selectedDate)); // Send the selected date as a string to the parent on Save
    setCalenderOpen(!calenderOpen);
    // sessionStorage.setItem("reportCalenderDate", formattedDate);
  };
  const handleCloseCalender = () => {
    setCalenderOpen(!calenderOpen);
  };

  useEffect(() => {
    console.log("calender-selected-date", selectedDate);
  }, [selectedDate]);

  return (
    <div className="calender-page-container">
      <div className="calender-page-header">
        <span>Calender</span>
        <MdClose className="popup-close-button" onClick={handleCloseCalender} />
      </div>
      <div className="calender-page-margin-container">
        <div className="calender-page-content">
          <div className="calender-in-title-container">
            <label>Date</label>
            <div className="calender-text-cotainer">
              <input
                className="calender-in-input"
                placeholder="Aug 5"
                disabled
                type="text"
                value={selectedDate.toLocaleDateString("en-GB")} // Format the date as a string
              />
            </div>
          </div>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
      </div>
      <div className="calender-page-footer">
        <div className="footer-btn-container">
          <button
            className="calender-page-cancel-btn"
            onClick={handleCloseCalender}
          >
            Cancel
          </button>
          <button onClick={handleSave} className="calender-page-save-btn">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Calender;
