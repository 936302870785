import React, { useEffect, useState } from "react";
import "./RaiseTicket.css";
import { MdClose } from "react-icons/md";
import { HiOutlineUpload } from "react-icons/hi";
import { addNewTicket, fetchTickets } from "../../../redux/action/ticket";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown-select";
import { getClientDetailsFromLocalStorage } from "../../../components/LocalStorage";
import {
  addSupportCommentFiles,
  addSupportFiles,
} from "../../../redux/action/ticket";
import dummyPdfPreview from "../Images/dummyPdfPreview.png";
import dummyDocPreview from "../Images/dummyDocumentPreview.png";
function RaiseTicket({ raiseTicketOpen, setRaiseTicketOpen }) {
  // const { alltickets, loading } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const [curClientId, setcurClientId] = useState(null);
  const [newReason, setNewReason] = useState("");
  const [newCall, setNewCall] = useState("false");
  const [newSubject, setNewSubject] = useState("");
  const [newStatus, setNewStatus] = useState("open");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [ticketUploadFiles, setTicketUploadFiles] = useState(null);
  const [fileNames, setFileNames] = useState(null);
  const [supportComment, setSupportComment] = useState("");
  const files = useSelector((state) => state.ticket.allfiles);
  const ticket = useSelector((state) => state.ticket.ticket);
  const [curTicketId, setCurTicketId] = useState(false);
  console.log("ticket-current", ticket);
  // useEffect(()=>{
  //   if(ticket){
  //     setCurTicketId(ticket._id);
  //   }
  // },[ticket])
  console.log(curTicketId);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      setcurClientId(storedClientDetails.client);
      // Update clientDetails state with the value from localStorage
      dispatch({ type: "SET_CLIENT_DETAILS", payload: storedClientDetails });
    }
  }, [dispatch]);
  // const tickets = alltickets?.map((ticket) => ({
  //   id: `${ticket._id}`,
  //   client: `${ticket.client}`,
  // }));
  console.log(curClientId);
  const handleCloseRaiseTicket = () => {
    setRaiseTicketOpen(!raiseTicketOpen);
  };

  const handleAddTicket = async () => {
    console.log(fileNames);
    const newFilesToUpload = ticketUploadFiles?.filter(
      (file) => !files?.includes(file.name)
    );
    if (newReason === "" || newSubject === "") {
      alert("please fill the reason and descritpion");
    } else {
      console.log(newFilesToUpload);

      await dispatch(
        addNewTicket(
          curClientId,
          newReason,
          newCall,
          newSubject,
          newStatus,
          fileNames,
          newFilesToUpload
        )
      );
      if (
        ((fileNames !== null) !== "" || ticketUploadFiles !== null) &&
        curClientId !== null
      ) {
        if (newFilesToUpload && newFilesToUpload.length > 0) {
          // await dispatch(
          //   addSupportCommentFiles(newFilesToUpload, curClientId,curTicketId) // addSupporCommentFiles uplaods the file to aws
          // );
        }
      }

      await dispatch(fetchTickets());
    }
    setRaiseTicketOpen(false);
  };
  const options = [
    { label: "Software", value: "Software" },
    { label: "Hardware", value: "Hardware" },
  ];

  const handleReasonChange = (event) => {
    setNewReason(event.target.value);
  };

  const handleCallRequestChange = (event) => {
    setNewCall(event.target.checked ? "true" : "false"); // Convert boolean to string
  };
  const handleDescriptionChange = (event) => {
    setNewSubject(event.target.value);
  };
  const handleFileChange = async (event) => {
    const files = event.target.files;
    console.log(files);
    const filesToUpload = Array.from(files).slice(0, 2); // Limiting to the first two files
    const fileNames = filesToUpload.map((file) => file.name);
    setFileNames(fileNames);

    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("file", file);
    });

    setTicketUploadFiles(filesToUpload);

    // Read the selected files and update uploadImages state
    const updatedImages = [];
    const updatedFiles = [];
    for (let i = 0; i < Math.min(filesToUpload.length, 2); i++) {
      const reader = new FileReader();
      reader.onload = function (e) {
        updatedFiles.push({
          dataUrl: e.target.result,
          fileName: filesToUpload[i].name,
        });
        if (updatedFiles.length === Math.min(filesToUpload.length, 2)) {
          setUploadFiles(updatedFiles);
        }
      };
      reader.readAsDataURL(filesToUpload[i]);
    }
  };

  console.log("files", ticketUploadFiles);
  console.log("files", uploadFiles);

  const handleRemoveFile = (indexToRemove) => {
    setUploadFiles(uploadFiles.filter((_, index) => index !== indexToRemove));
  };
  return (
    <div className="download-container">
      <div className="download-header">
        <span>Raise Ticket</span>
        <MdClose
          className="popup-close-button"
          onClick={handleCloseRaiseTicket}
        />
      </div>
      <div className="download-margin-container">
        <div className="download-content">
          <div className="reason-container">
            <label>Reason</label>
            <Dropdown
              className="reason-select"
              options={options}
              style={{
                padding: "0.75rem",
                border: "1px solid rgb(223, 227, 232)",
                borderRadius: "0.25rem",
              }}
              onChange={(values) => setNewReason(values[0]?.value || "")}
              values={[{ value: newReason }]}
              placeholder="Select a reason"
            />
          </div>
          <div className="description-container">
            <label>Description</label>
            <textarea
              className="description-text"
              rows="4"
              onChange={handleDescriptionChange}
              value={newSubject}
            ></textarea>
          </div>
          <div className="call-request-container">
            <input
              className="call-request-checkbox"
              type="checkbox"
              onChange={handleCallRequestChange}
              checked={newCall === "true"}
            />
            <label>Request a call</label>
          </div>
          <div className={"upload-files-container-uploader"}>
            {uploadFiles.map((fileData, index) => {
              const { dataUrl, fileName } = fileData;
              let backgroundImageUrl;
              const mimeType = dataUrl
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
              if (mimeType === "application/pdf") {
                backgroundImageUrl = `url(${dummyPdfPreview})`; // Set background to dummyPdf for PDF files
              } else if (
                mimeType === "application/msword" ||
                mimeType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                mimeType === "application/wps-office.doc"
              ) {
                backgroundImageUrl = `url(${dummyDocPreview})`; // Set background to dummyDoc for DOC/DOCX files
              } else {
                backgroundImageUrl = `url(${dataUrl})`; // Use the data URL for other file types
              }

              return (
                <div
                  className="uploaded-image"
                  key={index}
                  style={{
                    backgroundImage: backgroundImageUrl,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="uploaded-image-close"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <MdClose />
                  </div>
                  <span className="file-preview-title">{fileName}</span>{" "}
                  {/* Display the file name */}
                </div>
              );
            })}
          </div>
          <div className="file-upload-container">
            <label>Files</label>
            <div className="upload-files-container support-files-upload-container">
              {/* Render uploaded Files */}

              <label
                for="fileInput"
                class="ticket-upload-btn support-ticket-upload-btn"
              >
                <input
                  type="file"
                  id="fileInput"
                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                />
                <div class="square-box">
                  <span>Upload</span>
                  {/* <div class="plus-icon">+</div> */}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="download-footer">
        <div className="footer-btn-container">
          <button
            className="download-cancel-btn"
            onClick={handleCloseRaiseTicket}
          >
            Cancel
          </button>
          <button className="download-download-btn" onClick={handleAddTicket}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default RaiseTicket;
