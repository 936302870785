import { server } from "../store";
import axios from "axios";

export const fetchClients = () => async (dispatch) => {
  try {
    dispatch({ type: "clientfetchRequest" });
    const { data } = await axios.get(`${server}/client/all-clients`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "clientfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "clientfetchFailed",
      payload: error?.message,
    });
  }
};
// actionTypes.js
export const CLIENT_LOCATIONS_FETCH_REQUEST = "clientLocationsFetchRequest";
export const CLIENT_LOCATIONS_FETCH_SUCCESS = "clientLocationsFetchSuccess";
export const CLIENT_LOCATIONS_FETCH_FAILED = "clientLocationsFetchFailed";

// Action to fetch client locations by client ID
export const fetchClientLocations = (clientId) => async (dispatch) => {
  try {
    // Dispatch request start action
    dispatch({ type: "clientLocationsFetchRequest" });

    // Make the API request to fetch locations
    const { data } = await axios.get(`${server}/client/client-locations`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        id: clientId,
      },
      withCredentials: true,
    });

    console.log(data);

    // Dispatch success action with fetched data
    dispatch({ type: "clientLocationsFetchSuccess", payload: data.locations });
  } catch (error) {
    // Dispatch failure action with error message
    dispatch({
      type: "clientLocationsFetchFailed",
      payload: error?.message,
    });
  }
};

