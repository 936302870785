import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <div className='loading-circle-container'>
      <div className="loading-circle"></div>
    </div>
  );
};

export default LoadingSpinner;