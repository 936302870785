const data = {
  selectedDate: null,
  selectedDateFormatted: null,
  expandImgUrl: null,
  expandImgId: null,
  dummyReportCommentId: null,
  newComment: null,
  statusTicketCurrent: null,
  fromTime: null,
  toTime: null,
  reasonCurrent: null,
  calenderDate: null,
  loggedIn: false,
  supportComment: null,
  curTicketId: null,
  curUser: null,
  ticketUploadFiles: null,
  fileNames: null,
};
const childReducer = (state = data, action) => {
  switch (action.type) {
    case "child/updateSelectedDate":
      return { ...state, selectedDate: action.payload };
    case "child/updateSelectedDateFormatted":
      return { ...state, selectedDateFormatted: action.payload };
    case "child/updateExpandImageUrl":
      return {
        ...state,
        expandImgUrl: action.payload.url,
        expandImgId: action.payload.id,
      };

    case "child/updateCommentReport":
      return {
        ...state,
        dummyReportCommentId: action.payload.dummyReportCommentId,
        newComment: action.payload.newComment,
      };
    case "child/updateTicketsFilter":
      return {
        ...state,
        statusTicketCurrent: action.payload.statusTicketCurrent,
        fromTime: action.payload.fromTime,
        toTime: action.payload.toTime,
        calenderDate: action.payload.calenderDate,
        reasonCurrent: action.payload.reasonCurrent,
      };
    case "child/updateloggedIn":
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
      };
    case "child/updateSupportComments":
      return {
        ...state,
        supportComment: action.payload.supportComment,
        curTicketId: action.payload.curTicketId,
        curUser: action.payload.curUser,
        ticketUploadFiles: action.payload.ticketUploadFiles,
        fileNames: action.payload.fileNames,
      };
    default:
      return state;
  }
};

export const updateSelectedDate = (payload) => ({
  type: "child/updateSelectedDate",
  payload,
});
export const updateSelectedDateFormatted = (payload) => ({
  type: "child/updateSelectedDateFormatted",
  payload,
});
export const updateExpandImageUrl = (payload) => ({
  type: "child/updateExpandImageUrl",
  payload,
});

export const updateCommentReport = (payload) => ({
  type: "child/updateCommentReport",
  payload,
});
export const updateTicketsFilter = (payload) => ({
  type: "child/updateTicketsFilter",
  payload,
});
export const updateLoggedIn = (payload) => ({
  type: "child/updateLoggedIn",
  payload,
});
export const updateSupportComments = (payload) => ({
  type: "child/updateSupportComments",
  payload,
});

export default childReducer;
