import { server } from "../store";
import axios from "axios";

export const fetchReports = () => async (dispatch) => {
  try {
    dispatch({ type: "reportfetchRequest" });
    const { data } = await axios.get(`${server}/report/allreports`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "reportfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "reportfetchFailed",
      payload: error?.message,
    });
  }
};

export const fetchDailyReportDataRequest = () => ({
  type: "FETCH_DAILY_REPORT_DATA_REQUEST",
});

export const fetchDailyReportDataSuccess = (data) => ({
  type: "FETCH_DAILY_REPORT_DATA_SUCCESS",
  payload: data,
});

export const fetchDailyReportDataFailure = (error) => ({
  type: "FETCH_DAILY_REPOPRT_DATA_FAILURE",
  payload: error,
});

export const fetchDailyReports = (client) => async (dispatch) => {
  try {
    dispatch(fetchDailyReportDataRequest());
    const res = await axios.get(`${server}/report/dailyreport`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        client, // pass the client as a query parameter
      },
      withCredentials: true,
    });
    dispatch(fetchDailyReportDataSuccess(res.data));
  } catch (error) {
    dispatch(fetchDailyReportDataFailure(error));
  }
};

export const fetchRecentReportDataRequest = () => ({
  type: "FETCH_RECENT_REPORT_DATA_REQUEST",
});

export const fetchRecentReportDataSuccess = (data) => ({
  type: "FETCH_RECENT_REPORT_DATA_SUCCESS",
  payload: data,
});

export const fetchRecentReportDataFailure = (error) => ({
  type: "FETCH_RECENT_REPOPRT_DATA_FAILURE",
  payload: error,
});

export const fetchRecentReports = (client) => async (dispatch) => {
  try {
    dispatch(fetchRecentReportDataRequest());
    await axios
      .get(`${server}/report/recentreport`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          client, // pass the client as a query parameter
        },
        withCredentials: true,
      })
      .then((res) => {
        dispatch(fetchRecentReportDataSuccess(res.data));
      });
  } catch (error) {
    dispatch(fetchRecentReportDataFailure(error));
  }
};

export const fetchfilteredReportDataRequest = () => ({
  type: "FETCH_FILTERED_REPORT_DATA_REQUEST",
});

export const fetchfilteredReportDataSuccess = (data) => ({
  type: "FETCH_FILTERED_REPORT_DATA_SUCCESS",
  payload: data,
});

export const fetchfilteredReportDataFailure = (error) => ({
  type: "FETCH_FILTERED_REPOPRT_DATA_FAILURE",
  payload: error,
});

export const fetchfilteredReports =
  (client, startDate, endDate) => async (dispatch) => {
    try {
      dispatch(fetchfilteredReportDataRequest());
      await axios
        .get(`${server}/report/filteredreport`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            client: client,
            startDate: startDate,
            endDate: endDate,
          },
          withCredentials: true,
        })
        .then((res) => {
          dispatch(fetchfilteredReportDataSuccess(res.data));
        });
    } catch (error) {
      dispatch(fetchfilteredReportDataFailure(error));
    }
  };

export const editReport =
  (id, statusCurrent, tagCurrent, assignCurrent) => async (dispatch) => {
    try {
      dispatch({ type: "reportfetchRequest" });
      const { data } = await axios.post(
        `${server}/report/editreport`,
        { id, statusCurrent, tagCurrent, assignCurrent },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(data);
      dispatch({ type: "reportfetchSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "reportfetchFailed",
        payload: error?.message,
      });
    }
  };

export const deleteReport = (id) => async (dispatch) => {
  try {
    dispatch({ type: "reportdeleteRequest" });
    const { data } = await axios.put(
      `${server}/report/deletereport`,
      { id },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "reportdeleteSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "reportdeleteFailed",
      payload: error?.message,
    });
  }
};
export const addNewComment =
  (name, userid, id, comment) => async (dispatch) => {
    try {
      dispatch({ type: "addCommentRequest" });
      const { data } = await axios.post(
        `${server}/report/addComment`,
        { name, userid, id, comment },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      dispatch({ type: "addCommentSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "addCommentFailed",
        payload: error?.message,
      });
    }
  };

export const findReports = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: "reportfetchRequest" });
    const { data } = await axios.post(
      `${server}/report/find-reports `,
      { clientId },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "reportfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "reportfetchFailed",
      payload: error?.message,
    });
  }
};
export const findReportDates = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: "reportDatesFetchRequest" });
    const { data } = await axios.get(
      `${server}/report/dates?clientId=${clientId} `,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "reportDatesFetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "reportDatesFetchFailed",
      payload: error?.message,
    });
  }
};
export const fetchTags = () => async (dispatch) => {
  try {
    dispatch({ type: "tagfetchRequest" });
    const { data } = await axios.get(`${server}/report/tags`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({ type: "tagfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "tagfetchFailed",
      payload: error?.message,
    });
  }
};
export const fetchLocations = () => async (dispatch) => {
  try {
    dispatch({ type: "locationfetchRequest" });
    const { data } = await axios.get(`${server}/report/locations`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "locationfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "locationfetchFailed",
      payload: error?.message,
    });
  }
};
export const fetchStatus = () => async (dispatch) => {
  try {
    dispatch({ type: "statusfetchRequest" });
    const { data } = await axios.get(`${server}/report/status`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "statusfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "statusfetchFailed",
      payload: error?.message,
    });
  }
};
export const fetchViolationtypes = () => async (dispatch) => {
  try {
    dispatch({ type: "violationTypefetchRequest" });
    const { data } = await axios.get(`${server}/report/violationtypes`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "violationTypefetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "violationTypefetchFailed",
      payload: error?.message,
    });
  }
};
export const fetchNotifications = (client) => async (dispatch) => {
  try {
    dispatch({ type: "notificationfetchRequest" });
    const { data } = await axios.get(`${server}/notification/unseen`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        client,
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "notificationfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "notificationfetchFailed",
      payload: error?.message,
    });
  }
};
export const changeNotificationStatus = () => async (dispatch) => {
  try {
    dispatch({ type: "notificationStatusChangeRequest" });
    const { data } = await axios.put(`${server}/notification/status`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "notificationSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "notificationStatusChangeFailed",
      payload: error?.message,
    });
  }
};

export const fetchDownloadPdf = () => async (dispatch) => {
  try {
    dispatch({ type: "pdffetchRequest" });
    const { data } = await axios.get(`${server}/report/download-pdf`, {
      headers: {
        "Content-Type": "application/pdf",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "pdffetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "pdffetchFailed",
      payload: error?.message,
    });
  }
};

export const fetchPdfDataRequest = () => ({
  type: "FETCH_PDF_DATA_REQUEST",
});

export const fetchPdfDataSuccess = (data) => ({
  type: "FETCH_PDF_DATA_SUCCESS",
  payload: data,
});

export const fetchPdfDataFailure = (error) => ({
  type: "FETCH_PDF_DATA_FAILURE",
  payload: error,
});

export const fetchPdfData = (
  startDate,
  endDate,
  startTime,
  endTime,
  clientId
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPdfDataRequest());

      const response = await axios.get(`${server}/report/download-pdf`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          clientId: clientId,
        },
        responseType: "arraybuffer",
        withCredentials: true,
      });

      dispatch(fetchPdfDataSuccess(response.data));
    } catch (error) {
      console.error("Error fetching PDF:", error);
      dispatch(fetchPdfDataFailure(error));
    }
  };
};
