// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdrCalendarWrapper{
    z-index:100;
} 
.rdrDateDisplayWrapper{
    display:none;
}
.rdrCalendarWrapper{
    border:1px solid grey; 
}
.support-dropdown-style{
    margin-top: 16px;
    width: 100%;
}
.support-filter-select{
    padding:0.5rem;
    padding-left:0.5rem;
}
.support-filter-calender-container{
    display: flex;
    align-items: center;
    width:100%;
    justify-content: space-between;
    padding:0.75rem;
    border:1px solid rgb(196, 196, 196);
    border-radius:0.25rem;
} 
.support-filter-calender-container:hover {
    cursor:pointer;
}
@media only screen and (max-height: 700px) and (min-height: 300px) {
    .support-dropdown-style{
        margin-top: 4px;
    }
    .time-header-label{
        margin-bottom:0.25rem;
    }
        /* .MuiTypography-root.MuiTypography-body1{
            padding-left: 8px;
        } */
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
        padding-left:16px;
        padding:8px;
    }
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.Mui-readOnly.MuiInputBase-readOnly{
        padding-left:16px;
        padding:12px;
        font-size:14px;
    }
    
    }`, "",{"version":3,"sources":["webpack://./src/pages/support/Filter/Filter.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,8BAA8B;IAC9B,eAAe;IACf,mCAAmC;IACnC,qBAAqB;AACzB;AACA;IACI,cAAc;AAClB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,qBAAqB;IACzB;QACI;;WAEG;IACP;QACI,iBAAiB;QACjB,WAAW;IACf;IACA;QACI,iBAAiB;QACjB,YAAY;QACZ,cAAc;IAClB;;IAEA","sourcesContent":[".rdrCalendarWrapper{\r\n    z-index:100;\r\n} \r\n.rdrDateDisplayWrapper{\r\n    display:none;\r\n}\r\n.rdrCalendarWrapper{\r\n    border:1px solid grey; \r\n}\r\n.support-dropdown-style{\r\n    margin-top: 16px;\r\n    width: 100%;\r\n}\r\n.support-filter-select{\r\n    padding:0.5rem;\r\n    padding-left:0.5rem;\r\n}\r\n.support-filter-calender-container{\r\n    display: flex;\r\n    align-items: center;\r\n    width:100%;\r\n    justify-content: space-between;\r\n    padding:0.75rem;\r\n    border:1px solid rgb(196, 196, 196);\r\n    border-radius:0.25rem;\r\n} \r\n.support-filter-calender-container:hover {\r\n    cursor:pointer;\r\n}\r\n@media only screen and (max-height: 700px) and (min-height: 300px) {\r\n    .support-dropdown-style{\r\n        margin-top: 4px;\r\n    }\r\n    .time-header-label{\r\n        margin-bottom:0.25rem;\r\n    }\r\n        /* .MuiTypography-root.MuiTypography-body1{\r\n            padding-left: 8px;\r\n        } */\r\n    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{\r\n        padding-left:16px;\r\n        padding:8px;\r\n    }\r\n    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.Mui-readOnly.MuiInputBase-readOnly{\r\n        padding-left:16px;\r\n        padding:12px;\r\n        font-size:14px;\r\n    }\r\n    \r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
