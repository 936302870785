// import {
//     findReportRequest,
//     findReportSuccess,
//     findReportFailure
//   } from '../action/report';
  
  
//   const report = {
//     data: [],
//     loading: false,
//     error: null,
//   };
  
  
//   const findReportReducer = (state = report, action) => {
//     switch (action.type) {
//       case findReportRequest().type:
//         return { ...state, loading: true, error: null };
//       case findReportSuccess().type:
//         return { ...state, loading: false, data: action.payload };
//       case findReportFailure().type:
//         return { ...state, loading: false, error: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default findReportReducer;

import {
  CLIENT_LOCATIONS_FETCH_REQUEST,
  CLIENT_LOCATIONS_FETCH_SUCCESS,
  CLIENT_LOCATIONS_FETCH_FAILED,
} from "../action/client";

const initialState = {
  loading: false,
  locations: [],
  error: null,
};

export const clientLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_LOCATIONS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CLIENT_LOCATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload,
      };
    case CLIENT_LOCATIONS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
