import React from 'react'
import LeftNav from '../../components/LeftNav/LeftNav'
import TopBar from '../../components/TopBar/TopBar'
// import ReportPage from '../report'
import Overview from '../overview/Overview'
import './dashboard.css'
const Dashboard = () => {
  return (
    <div className='dashboard-main'>
    <div className='sidebarCss'>
    <LeftNav/></div>
    <div className='mainCss'>
    <TopBar/>
    <Overview/>
    </div>
    </div>
  )
}

export default Dashboard
