import { createReducer } from "@reduxjs/toolkit";

const clientReducer = createReducer(
  {},
  {
    clientfetchRequest: (state) => {
      state.loading = true;
    },
    clientfetchSuccess: (state, action) => {
      state.loading = false;
      state.allClients = action.payload.clients;
      state.message = action.payload.message;
    },
    clientfetchFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
);

export default clientReducer;
