// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../icons/PlusJakartaSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'PlusJakartaSans'; /* Choose a name for your font */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  /* Additional font properties can be specified, such as font-weight, font-style, etc. */
}

.right-topbar{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    height: 64px;
}
.right-topbar-right{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.notification-button,.profile-button{
    width:2rem;
    height:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border:transparent;
    background-color: transparent;
}
.profile-icon{
    height:2rem;
    width:2rem;
}

.user-container{
    font-family: PlusJakartaSans;
    font-size: 18px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/TopBar/TopBar.css"],"names":[],"mappings":"AACA;EACE,8BAA8B,EAAE,gCAAgC;EAChE,+DAA6E;EAC7E,uFAAuF;AACzF;;AAEA;IACI,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,6BAA6B;AACjC;AACA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');\r\n@font-face {\r\n  font-family: 'PlusJakartaSans'; /* Choose a name for your font */\r\n  src: url('../icons/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');\r\n  /* Additional font properties can be specified, such as font-weight, font-style, etc. */\r\n}\r\n\r\n.right-topbar{\r\n    display: flex;\r\n    width:100%;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 15px;\r\n    height: 64px;\r\n}\r\n.right-topbar-right{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 1rem;\r\n}\r\n.notification-button,.profile-button{\r\n    width:2rem;\r\n    height:2rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 50%;\r\n    border:transparent;\r\n    background-color: transparent;\r\n}\r\n.profile-icon{\r\n    height:2rem;\r\n    width:2rem;\r\n}\r\n\r\n.user-container{\r\n    font-family: PlusJakartaSans;\r\n    font-size: 18px;\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
