import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import "./Notification.css";
import { FaCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  changeNotificationStatus,
} from "../../../../redux/action/report";
import { notification } from "antd";
import noNotificationImg from "../images/no-notification.png";
import { getClientDetailsFromLocalStorage } from "../../../../components/LocalStorage";

function Notification({ setNotificationOpen, notificationOpen }) {
  const { allNotifications, loading } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      dispatch(fetchNotifications(storedClientDetails.client));
    }
  }, []);

  const [activeNotificationId, setActiveNotificationId] = useState(null);

  const notifications = allNotifications?.map((obj) => ({
    client: `${obj.client}`,
    message: `${obj.message}`,
    status: `${obj.status}`,
    type: `${obj.type}`,
    id: `${obj._id}`,
    createdAt: `${obj.createdAt}`,
    updateAt: `${obj.updatedAt}`,
  }));

  const changeToSeen = () => {
    dispatch(changeNotificationStatus());
  };

  const handleMouseOver = (notificationId) => {
    setActiveNotificationId(notificationId);
  };

  const handleMouseLeave = () => {
    setActiveNotificationId(null);
  };
  const handleCloseNotification = () => {
    setNotificationOpen(!notificationOpen);
  };

  return loading ? null : (
    <div className="notification-container">
      <div className="notification-header">
        <span>Notification</span>
        <MdClose
          className="popup-close-button"
          onClick={handleCloseNotification}
        />
      </div>
      {notifications && notifications.length > 0 ? (
        <div className="notification-content">
          <div className="content-header">
            <span>{`You have ${notifications.length} unread notification(s)`}</span>
            <button className="mark-read-btn" onClick={changeToSeen}>
              Mark all read
            </button>
          </div>
          <div className="notification-main">
            {notifications.map((notification) => {
              const createdAtDate = new Date(notification.createdAt);
              const timeDifference = Date.now() - createdAtDate.getTime();
              const daysDifference = Math.floor(
                timeDifference / (1000 * 60 * 60 * 24)
              );

              return (
                <div
                  key={notification.id}
                  className={`single-notification ${
                    notification.status === "read" ? "read" : ""
                  }`}
                  onMouseOver={() => handleMouseOver(notification.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={`read-icon-container ${
                      activeNotificationId === notification.id ? "" : "not"
                    }`}
                  >
                    <FaCircle className="read-icon" />
                  </div>
                  <div className="incident-container">
                    <span>{notification.message}</span>
                    <span>{`${daysDifference} days ago`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="no-notfication-container">
          <img
            className="no-notification-img"
            src={noNotificationImg}
            alt="No Notifications"
          />
        </div>
      )}
    </div>
  );
}
//   return loading === true ? null :(
//     <div className='notification-container'>
//         <div className='notification-header'>
//             <span>Notification</span>
//             <MdClose />
//         </div>
//         <div className='notification-content'>
//             <div className='content-header'>
//                 <span>You have 1 unread notification</span>
//                 <button className='mark-read-btn'>Mark all read</button>
//             </div>
//             <div className='notification-main'>
//                 <div className='single-notification '>
//                     <div className='notread-icon-container'><FaCircle className='read-icon' /></div>
//                     <div className='incident-container'>
//                         <span>Incident #23175 - email has been sent</span>
//                         <span>2 Days</span>
//                     </div>
//                 </div>
//                 <div className='single-notification read' >
//                     <div className='read-icon-container'><FaCircle className='read-icon' /></div>
//                     <div className='incident-container'>
//                         <span>New Ticket - #26175 has been created successfully</span>
//                         <span>2 Days</span>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     </div>
//   )

export default Notification;
