// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-main {
    height: 100vh;
    display: flex;
    flex-direction: row;
  }

  .sidebarCss{
    width: 8%;
    min-width: 150px;
    height:100%;
  }
  
  .mainCss{
    width: 92%;
height: 100%;
  }

  body {
    overflow-x: hidden; /* Disable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
  }

  @media (max-width: 1300px), (max-height: 850px) {
    .sidebarCss{
      min-width: 100px;
    }
    
  }`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,SAAS;IACT,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,UAAU;AACd,YAAY;EACV;;EAEA;IACE,kBAAkB,EAAE,iCAAiC;IACrD,kBAAkB,EAAE,+BAA+B;EACrD;;EAEA;IACE;MACE,gBAAgB;IAClB;;EAEF","sourcesContent":[".dashboard-main {\r\n    height: 100vh;\r\n    display: flex;\r\n    flex-direction: row;\r\n  }\r\n\r\n  .sidebarCss{\r\n    width: 8%;\r\n    min-width: 150px;\r\n    height:100%;\r\n  }\r\n  \r\n  .mainCss{\r\n    width: 92%;\r\nheight: 100%;\r\n  }\r\n\r\n  body {\r\n    overflow-x: hidden; /* Disable horizontal scrolling */\r\n    overflow-y: hidden; /* Disable vertical scrolling */\r\n  }\r\n\r\n  @media (max-width: 1300px), (max-height: 850px) {\r\n    .sidebarCss{\r\n      min-width: 100px;\r\n    }\r\n    \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
