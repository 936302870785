// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    width: 75px;
    height: 20px;
  }
  
  .label .placeholder {
    position: fixed;
    height: 20px;
    top: 0;
    left: 0;
    font-family: "Plus Jakarta Sans-Bold", Helvetica;
    font-weight: 700;
    color: #006100;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    white-space: nowrap;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/report/components/tags.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,MAAM;IACN,OAAO;IACP,gDAAgD;IAChD,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".label {\r\n    width: 75px;\r\n    height: 20px;\r\n  }\r\n  \r\n  .label .placeholder {\r\n    position: fixed;\r\n    height: 20px;\r\n    top: 0;\r\n    left: 0;\r\n    font-family: \"Plus Jakarta Sans-Bold\", Helvetica;\r\n    font-weight: 700;\r\n    color: #006100;\r\n    font-size: 14px;\r\n    letter-spacing: 0;\r\n    line-height: 20px;\r\n    white-space: nowrap;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
