import React from "react";

function DropdownArrowIcon({classes}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      class={classes}
      height="1rem"
      width="1rem"
    >
        <path  class="cls-1" d="M11.8,15.4c0,0,.1-.1.1-.2,2.2-2.2,4.4-4.4,6.6-6.6.4-.4.8-.8,1.2-1.2.3-.3.6-.4.9-.4.8,0,1.6.5,1.6,1.4,0,.5-.1.9-.4,1.2-1.1,1.1-2.3,2.3-3.4,3.4-1.4,1.4-2.8,2.8-4.3,4.3-.4.4-.9.9-1.3,1.3-.3.3-.7.5-1.2.4-.4,0-.7-.2-.9-.4-1-1-2-2-3-3-1.6-1.6-3.2-3.2-4.7-4.7-.4-.4-.9-.9-1.3-1.3-.2-.2-.4-.5-.4-.8,0-.5,0-.9.3-1.2.3-.3.6-.5,1-.5.4,0,.8,0,1.1.4,0,0,0,0,.1,0,1.8,1.8,3.7,3.7,5.5,5.5.8.8,1.6,1.6,2.4,2.4,0,0,0,0,0,0Z"/>
    </svg>
  );
}

export default DropdownArrowIcon;