import React from 'react'
import LeftNav from '../../components/LeftNav/LeftNav'
import TopBar from '../../components/TopBar/TopBar'
import SupportPage from '.'
import '../dashboard/dashboard.css'
const support = () => {
  return (
    <div className='dashboard-main'>
    <div className='sidebarCss'>
    <LeftNav/></div>
    <div className='mainCss'>
    <TopBar/>
    <SupportPage/>
    </div>
    </div>
  )
}

export default support
