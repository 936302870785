import React from 'react'
import noRecords from "../../images/no-records-found.png";
function NoRecords() {
  return (
    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(248, 249, 254)",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "12rem",
                        borderRadius: "0.5rem",
                        fontFamily: "PlusJakartaSans",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={noRecords}
                        width="150rem"
                        height="auto"
                        style={{ marginBottom: "1rem" }}
                        alt="No Records Found"
                      />
                      <p>No Records Found!</p>
                    </div>
  )
}

export default NoRecords
