import {
    fetchDailyReportDataRequest,
    fetchDailyReportDataSuccess,
    fetchDailyReportDataFailure
  } from '../action/report';
  
  
  const report = {
    data: [],
    loading: false,
    error: null,
  };
  
  
  const dailyReportReducer = (state = report, action) => {
    switch (action.type) {
      case fetchDailyReportDataRequest().type:
        return { ...state, loading: true, error: null };
      case fetchDailyReportDataSuccess().type:
        return { ...state, loading: false, data: action.payload };
      case fetchDailyReportDataFailure().type:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default dailyReportReducer;