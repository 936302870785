// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-circle-container{
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
    display: flex;  
}
.loading-circle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgb(13, 13, 48);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;AACA;IACI,oCAAoC;IACpC,kCAAkC;IAClC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loading-circle-container{\r\n    width: 100%;\r\n    height: 80%;\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: flex;  \r\n}\r\n.loading-circle {\r\n    border: 4px solid rgba(0, 0, 0, 0.1);\r\n    border-left-color: rgb(13, 13, 48);\r\n    border-radius: 50%;\r\n    width: 40px;\r\n    height: 40px;\r\n    animation: spin 1s linear infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    0% {\r\n      transform: rotate(0deg);\r\n    }\r\n    100% {\r\n      transform: rotate(360deg);\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
