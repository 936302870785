import React, { useEffect, useRef, useState } from "react";
import "./support.css";
import { BiFilterAlt } from "react-icons/bi";

import Ticket from "./Ticket/Ticket";
import { useDispatch, useSelector } from "react-redux";
import RaiseTicket from "./RiaseTicket/RaiseTicket";
import { fetchTickets } from "../../redux/action/ticket";
import Filter from "./Filter/Filter";
import NoRecords from "../../components/NoRecords/NoRecords";
import { getClientDetailsFromLocalStorage } from "../../components/LocalStorage";

function SupportPage() {
  const [raiseTicketOpen, setRaiseTicketOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const statusTicketCurrent = useSelector(
    (state) => state.child.statusTicketCurrent
  );
  const [currentClientID, setCurrentClientID] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredAllTickets, setFilteredAllTickets] = useState(null);
  const fromTime = useSelector((state) => state.child.fromTime);
  const toTime = useSelector((state) => state.child.toTime);
  const calenderDate = useSelector((state) => state.child.calenderDate);
  const reasonCurrent = useSelector((state) => state.child.reasonCurrent);
  const supportComment = useSelector((state) => state.child.supportComment);
  const curTicketId = useSelector((state) => state.child.curTicketId);
  const ticketUploadFiles = useSelector(
    (state) => state.child.ticketUploadFiles
  );
  const fileNames = useSelector((state) => state.child.fileNames);
  const curUser = useSelector((state) => state.child.curUser);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const mounted = useRef(false);
  const [refresh, setRefresh] = useState(new Date());

  console.log("%j", "ticketUploadFiles", ticketUploadFiles);
  console.log(fileNames);
  console.log(supportComment, curTicketId);
  console.log(reasonCurrent);
  console.log(statusTicketCurrent, calenderDate, fromTime, toTime);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      setCurrentClientID(storedClientDetails.client);
      setCurrentUserId(storedClientDetails._id);
      // Update clientDetails state with the value from localStorage
      dispatch({ type: "SET_CLIENT_DETAILS", payload: storedClientDetails });
    }
  }, []);
  const openRaiseTicketPopup = () => {
    setRaiseTicketOpen(!raiseTicketOpen);
  };
  const openFilterPopup = () => {
    setFilterOpen(!filterOpen);
  };
  const alltickets = useSelector((state) => state.ticket.alltickets);
  const loading = useSelector((state) => state.ticket.loading);
  useEffect(() => {
    if (alltickets && alltickets.length > 0) {
      setTickets(alltickets);
    }
  }, [alltickets]);
  useEffect(() => {
    if (tickets && tickets.length >= 0) {
      setFilteredAllTickets(
        tickets.filter((ticket) => ticket.client === currentClientID)
      );
    }
  }, [tickets, currentClientID]);
  console.log(tickets);
  console.log(currentClientID);
  console.log(filteredAllTickets);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTickets());
    const intervalId = setInterval(() => {
      setRefresh(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [dispatch, refresh]);

  useEffect(() => {
    const filteredTicketsIn =
      filteredAllTickets && filteredAllTickets.length > 0
        ? filteredAllTickets.filter((ticket) => {
            // Check if statusTicketCurrent is defined and the ticket's status matches it
            if (
              statusTicketCurrent &&
              statusTicketCurrent !== "All" &&
              ticket.status !== statusTicketCurrent
            ) {
              return false;
            }

            // Check if reasonCurrent is defined and the ticket's reason matches it
            if (
              reasonCurrent &&
              reasonCurrent !== "All" &&
              ticket.reason !== reasonCurrent
            ) {
              return false;
            }

            // Check if calenderDate is defined and the ticket's createdAt date falls within the range
            if (calenderDate) {
              const ticketDate = new Date(ticket.createdAt);
              const startDate = new Date(calenderDate[0]?.startDate);
              const endDate = new Date(calenderDate[0]?.endDate);

              // Set time components of startDate to start of the day (00:00:00) and endDate to end of the day (23:59:59)
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(23, 59, 59, 999);

              // Compare only the date parts
              if (ticketDate < startDate || ticketDate > endDate) {
                return false;
              }
            }

            // Check if fromTime and toTime are defined and the ticket's createdAt time falls within the range
            if (fromTime && toTime) {
              const startTimeParam = fromTime?.format("HH:mm:ss") || "00:00:00";
              const endTimeParam = toTime?.format("HH:mm:ss") || "00:00:00";
              const ticketDate = new Date(ticket.createdAt);
              const ticketTime = `${ticketDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${ticketDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}:00`;
              console.log(
                "ticketTime",
                ticketTime,
                "fromTime",
                startTimeParam,
                "toTime",
                endTimeParam
              );
              if (ticketTime < startTimeParam || ticketTime > endTimeParam) {
                return false;
              }
            }

            // If all conditions pass or if the criteria are not defined, include the ticket in the filteredTickets array
            return true;
          })
        : [];
    setFilteredTickets(filteredTicketsIn);
  }, [
    filteredAllTickets,
    calenderDate,
    fromTime,
    reasonCurrent,
    statusTicketCurrent,
    toTime,
  ]);
  return (
    <>
      {raiseTicketOpen && (
        <RaiseTicket
          raiseTicketOpen={raiseTicketOpen}
          setRaiseTicketOpen={(newState) => setRaiseTicketOpen(newState)}
          curClientId={currentClientID}
        />
      )}
      {filteredAllTickets &&
        filteredAllTickets.length > 0 &&
        filteredAllTickets.map((item, index) => (
          <div key={index}>
            {filterOpen && (
              <Filter
                alltickets={item}
                filterOpen={filterOpen}
                allTickets={alltickets}
                setFilterOpen={(newState) => setFilterOpen(newState)}
              />
            )}
          </div>
        ))}
      {/* {raiseTicketOpen&&(<RaiseTicket tickets={tickets} raiseTicketOpen={raiseTicketOpen} setRaiseTicketOpen={(newState) => setRaiseTicketOpen(newState)}/>)} */}
      <div className="support-container">
        <div className="right-container">
          <div className="support-in-container">
            <div className="support-in-title-container">
              <span>Support</span>
              <button className="ticket-button" onClick={openRaiseTicketPopup}>
                Raise Ticket
              </button>
            </div>
            <div className="support-ticket-container">
              <div className="ticket-title-container">
                <span>All Tickets ({filteredTickets?.length})</span>
                <button
                  className="ticket-filter-button"
                  onClick={openFilterPopup}
                >
                  Filter
                  <BiFilterAlt className="filter" />
                </button>
              </div>
              {/* Display NoRecords if filteredTickets is empty */}
              {filteredTickets && filteredTickets.length === 0 && <NoRecords />}
              <div className="tickets-container">
                {filteredTickets && filteredTickets.length > 0
                  ? filteredTickets.map((item, index) => (
                      <div key={index}>
                        <Ticket alltickets={item} loading={loading} />
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportPage;
