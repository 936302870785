import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const MeetingPopup = ({ anchor, dummyReport, setOpenMeeting }) => {
  const allUsers = useSelector((state) => state.user.allUsers);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [assignCurrent, setAssignCurrent] = useState({
    label: dummyReport.assigned,
    id: 0,
  });
  const toggleDrawerMeeting = (anchor, openMeeting) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenMeeting(openMeeting);
    setState({ ...state, [anchor]: openMeeting });
    setAssignCurrent(null);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`,
    };
  }
  const assign =
    (allUsers || []).map((user) => ({
      label: user.name,
      id: user._id,
      mail: user.email,
    })) || [];
  return (
    <Box
      className="report-edit-popup-container"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 340,
      }}
      role="presentation"
      onKeyDown={toggleDrawerMeeting(anchor, false)}
    >
      <div className="report-edit-popup-title-container">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Typography
            style={{
              marginLeft: "11px",
              fontFamily: "PlusJakartaSans",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              margin: "16px",
            }}
          >
            Schedule Meeting
          </Typography>
          <IconButton onClick={toggleDrawerMeeting(anchor, false)}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>
      </div>
      <div
        style={{
          margin: "14px",
          backgroundColor: "#F9FAFB",
          height: "140px",
          borderRadius: "0.5rem",
        }}
      >
        <Stack
          style={{ marginLeft: "10px", marginTop: "16px" }}
          flexDirection={"row"}
        >
          <img
            src={dummyReport.imagepath}
            alt="Report Img"
            width="100"
            height="55"
            style={{ marginTop: "4px" }}
          />
          <div>
            <div style={{ marginLeft: "12px", marginTop: "4px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                }}
              >
                #{dummyReport._id.slice(-4)}
              </Typography>
            </div>
            <div style={{ marginLeft: "12px", marginTop: "6px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
              >
                {moment.utc(dummyReport.createdAt).format("DD MMM, hh:mm:ss")}
              </Typography>
            </div>
          </div>
        </Stack>
        <Divider style={{ marginTop: "16px" }} />
        <Stack
          direction={"row"}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"column"}>
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Location
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.location}
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Incident
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.violationType}
            </Typography>
          </Stack>
        </Stack>
        <div className="meeting-content-footer-container">
          <div className="meeting-content">
            <div className="date-container">
              <label className="meeting-content-label">Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Basic date picker" />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="time-container">
              <div className="start-time-container">
                <label className="meeting-content-label">Start Time</label>
                <div className="time start"></div>
              </div>
              <div className="end-time-container">
                <label className="meeting-content-label">End Time</label>
                <div className="time end"></div>
              </div>
            </div>
            <div className="participants-container">
              <label className="meeting-content-label">Add Partcipants</label>
              <Autocomplete
                style={{
                  fontFamily: "PlusJakartaSans",
                  marginTop: "0.5rem",
                }}
                disablePortal
                id="assign"
                options={assign}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  setAssignCurrent(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Assign" />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Avatar
                      {...stringAvatar(`${option.label} ${option.label}`)}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                    {option.label}
                  </Box>
                )}
              />
            </div>
            <div className="details-cotainer">
              <label className="meeting-content-label">Add Details</label>
              <textarea
                className="details-textarea"
                rows="6" // You can adjust the number of rows as needed
                cols="40" // You can adjust the number of columns as needed
              />
            </div>
          </div>
          <div className="report-edit-popup-footer">
            <Button
              variant="contained"
              style={{
                marginLeft: "230px",
                marginTop: "16px",
                backgroundColor: "#4040F2",
                fontFamily: "PlusJakartaSans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default MeetingPopup;
