/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./analytics.css";
import Button from "react-bootstrap/Button";
import { PieChart, Pie } from "recharts";
import { DateRange } from "react-date-range";
import moment from "moment-timezone";
import "moment/locale/en-in"; // Import Indian English locale for proper formatting
import { connect } from "react-redux";
import { fetchfilteredReports } from "../../redux/action/report";
import { fetchClientLocations } from "../../redux/action/client";
import { getClientDetailsFromLocalStorage } from "../../components/LocalStorage";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import DropdownArrowIcon from "../report/components/images/DropdownArrow";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineCalendarMonth } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { ExpandMore } from "@mui/icons-material";

import dayjs from "dayjs";
const AnalyticsPage = ({
  filteredReportsData,
  fetchfilteredReports,
  fetchClientLocations,
  clientLocation,
}) => {
  const [selectedIncident, setSelectedIncident] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [week, setWeek] = useState(false);
  const [activeButton, setActiveButton] = useState("Today");
  const [calenderDate, setCalenderDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [dateRangeText, setDateRangeText] = useState("From - To");
  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    if (calenderDate.length > 0) {
      const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
      const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
      setDateRangeText(`${fromText} - ${toText}`);
    } else {
      setDateRangeText("From - To");
    }
  }, [calenderDate]);

  const handleMonthClick = () => {
    setCurrentMonth(true);
    setWeek(false);
    setActiveButton("Monthly");

    // Get the current date
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    // Set the start date to the first day of the selected month and year
    const startOfMonth = new Date(year, month, 1);
    startOfMonth.setHours(0, 0, 0, 0);

    // Set the end date to the last day of the selected month and year
    const endOfMonth = new Date(
      year,
      month,
      new Date(year, month + 1, 0).getDate()
    );
    endOfMonth.setHours(23, 59, 59, 999);
    setCalenderDate([
      {
        startDate: startOfMonth,
        endDate: endOfMonth,
        key: "selection",
      },
    ]);
  };

  const handleWeekClick = () => {
    setWeek(true);
    setActiveButton("Weekly");
    setCurrentMonth(false);
    const currentUTC = new Date();
    const startOfWeek = new Date(currentUTC.getTime());
    startOfWeek.setUTCHours(0, 0, 0, 0);
    startOfWeek.setUTCDate(
      startOfWeek.getUTCDate() - ((startOfWeek.getUTCDay() + 7) % 7)
    ); // Set to the first day of the current week

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 5); // Set to the end of the week (Saturday)
    endOfWeek.setUTCHours(23, 59, 59, 999); // Set the time to the end of the day

    setCalenderDate([
      { startDate: startOfWeek, endDate: endOfWeek, key: "selection" },
    ]);
  };
  const handleTodayClick = () => {
    setWeek(false);
    setCurrentMonth(false);
    setActiveButton("Today");

    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );

    setCalenderDate([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      fetchfilteredReports(
        storedClientDetails.client,
        formatEntriesDate(calenderDate[0].startDate),
        formatEntriesDate(calenderDate[0].endDate),
        false,
        false
      );
      fetchClientLocations(storedClientDetails.client);
    }
    const intervalId = setInterval(() => {
      setRefresh(new Date());
    }, 5000);

    return () => clearInterval(intervalId);
  }, [
    fetchfilteredReports,
    fetchClientLocations,
    currentMonth,
    week,
    calenderDate,
    refresh,
  ]);

  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
  };

  const handleViolationChange = (e) => {
    setSelectedIncident(e.target.value);
  };

  // Define the handleOutsideClick function before useEffect
  const handleOutsideClick = (event) => {
    if (calenderOpen && !event.target.closest(".analytics-download-calender")) {
      setCalenderOpen(false); // Close the calendar if clicked outside
    }
  };

  // Use the handleOutsideClick function in useEffect
  useEffect(() => {
    if (calenderOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [calenderOpen, handleOutsideClick]); // Only calenderOpen is needed in the dependency array

  const handleCalenderToggle = () => {
    setCalenderOpen(true);
  };

  const formatEntriesDate = (date) => {
    return moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
  };

  if (
    !filteredReportsData ||
    !filteredReportsData.reports ||
    !Array.isArray(filteredReportsData.reports)
  ) {
    return <LoadingSpinner />;
  }

  const incident = Array.from(
    new Set(
      filteredReportsData.reports.flatMap((report) =>
        report.violationType.split(",")
      )
    )
  );

  const incidentCount = (filteredReportsData.reports || []).reduce(
    (acc, report) => {
      const tags = (report.violationType || "").split(",");
      const reportLocation = (report.location || "").toLowerCase(); // Convert to lowercase for case-insensitive comparison

      if (
        !selectedArea ||
        selectedArea.length === 0 ||
        selectedArea.some((loc) =>
          reportLocation.includes(loc.toLowerCase())
        ) ||
        !selectedIncident ||
        selectedIncident.includes(report.violationType)
      ) {
        tags.forEach((tag) => {
          const trimmedTag = tag.trim();
          acc[trimmedTag] = (acc[trimmedTag] || 0) + 1;
        });
      }

      return acc;
    },
    {}
  );

  const pieChartData = Object.entries(incidentCount)
    .map(([name, count], index) => ({
      name,
      value: count,
      fill: getFillByIndex(index),
    }))
    .sort((a, b) => b.value - a.value);

  function getFillByIndex(index) {
    const colors = [
      "#FB8417",
      "#F0AE37",
      "#21975A",
      "#017FFA",
      "#D32F2F",
      "#EF5350",
      "#EAB71B",
      "#CDDC39",
      "#FFEB3B",
      "#BBDEFB",
      "#9CCC65",
      "#FFC107",
    ];
    return colors[index % colors.length];
  }
  console.log("clientLocation", clientLocation);
  const width = 180;
  const height = 180;
  const area = clientLocation;
  console.log("area", area);
  const filteredChartData =
    selectedIncident.length > 0
      ? pieChartData.filter((item) => selectedIncident.includes(item.name))
      : pieChartData;
  const filteredReports = filteredReportsData.reports.filter((report) => {
    const incidentFilter =
      selectedIncident.length === 0 ||
      selectedIncident.some((selectedTag) =>
        report.violationType.includes(selectedTag)
      );

    const areaFilter =
      selectedArea.length === 0 ||
      selectedArea.some((selectedTag) => report.location.includes(selectedTag));

    return incidentFilter && areaFilter;
  });

  function formatTime(time) {
    if (time) {
      const hour = new Date(time).getUTCHours();
      const formattedHour = hour.toString().padStart(2, "0");
      return `${formattedHour}:00`;
    }
    return "N/A";
  }
  let countArray, dataArray, maximumCount, graphTitle;

  const getCountsByTime = (reports, timeArray, getTimeFunction) => {
    const timeCounts = {};

    if (Array.isArray(reports) && reports.length > 0) {
      reports.forEach((entry) => {
        const formattedTime = getTimeFunction(entry.createdAt);
        timeCounts[formattedTime] = (timeCounts[formattedTime] || 0) + 1;
      });
    } else {
      console.error("Invalid reports format");
    }

    return timeArray.map((targetTime) => timeCounts[targetTime] || 0);
  };

  const getCountsByMonth = (reports, dataArray) => {
    const getAbbreviatedMonth = (dateString) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[new Date(dateString).getMonth()];
    };

    const reportsByMonth = reports.reduce((groupedReports, report) => {
      const month = getAbbreviatedMonth(report.createdAt);
      groupedReports[month] = (groupedReports[month] || 0) + 1;
      return groupedReports;
    }, {});

    return dataArray.map((month) => reportsByMonth[month] || 0);
  };

  const getCountsByDayOfWeek = (reports, dayArray) => {
    const getAbbreviatedDayOfWeek = (dateString) =>
      ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
        new Date(dateString).getDay()
      ];

    const reportsByDayOfWeek = reports.reduce((groupedReports, report) => {
      const dayOfWeek = getAbbreviatedDayOfWeek(report.createdAt);
      groupedReports[dayOfWeek] = (groupedReports[dayOfWeek] || 0) + 1;
      return groupedReports;
    }, {});

    return dayArray.map((day) => reportsByDayOfWeek[day] || 0);
  };

  if (week === false && currentMonth === false) {
    const targetTimeArray = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      "23:59",
    ];

    countArray = getCountsByTime(filteredReports, targetTimeArray, formatTime);
    dataArray = [
      "0",
      "1hr",
      "2hr",
      "3hr",
      "4hr",
      "5hr",
      "6hr",
      "7hr",
      "8hr",
      "9hr",
      "10hr",
      "11hr",
      "12hr",
      "13hr",
      "14hr",
      "15hr",
      "16hr",
      "17hr",
      "18hr",
      "19hr",
      "20hr",
      "21hr",
      "22hr",
      "23hr",
      "24hr",
    ];
    graphTitle = "Hours";
    maximumCount = Math.max(...countArray);
  } else if (currentMonth) {
    dataArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    countArray = getCountsByMonth(filteredReports, dataArray);
    graphTitle = "Months";
    maximumCount = Math.max(...countArray);
  } else {
    dataArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    graphTitle = "Days";
    countArray = getCountsByDayOfWeek(filteredReports, dataArray);
    maximumCount = Math.max(...countArray);
  }

  countArray = countArray || [];

  const chartData = {
    series: [
      {
        name: "series1",
        data: [...countArray],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.7,
            opacityFrom: 0.9,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        categories: dataArray,
        tickAmount: 24,
        title: {
          text: graphTitle,
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
      },
      yaxis: {
        min: 0,
        max: maximumCount <= 5 ? 5 : Math.ceil(maximumCount / 5) * 5,

        tickAmount: 5,
        title: {
          text: "Count",
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans",
          },
        },
        grid: {
          strokeDashArray: 10,
          stroke: "#FFF",
        },
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
      },
      colors: ["#DA9D66"],
      fill: {
        colors: ["#DA9D66"],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
    },
  };

  // Sort locations by count in descending order

  const locationCounts = filteredReports.reduce((counts, report) => {
    const location = report.location;
    counts[location] = (counts[location] || 0) + 1;
    return counts;
  }, {});

  const sortedLocations = Object.keys(locationCounts).sort(
    (a, b) => locationCounts[b] - locationCounts[a]
  );

  const top3Locations = sortedLocations.slice(0, 3);
  let barDataArray, countAreaArray, barTitle;

  countAreaArray = []; // Common initialization

  if (week === false && currentMonth === false) {
    barDataArray = [
      "1hr",
      "2hr",
      "3hr",
      "4hr",
      "5hr",
      "6hr",
      "7hr",
      "8hr",
      "9hr",
      "10hr",
      "11hr",
      "12hr",
      "13hr",
      "14hr",
      "15hr",
      "16hr",
      "17hr",
      "18hr",
      "19hr",
      "20hr",
      "21hr",
      "22hr",
      "23hr",
      "24hr",
    ];
    barTitle = "Hours";
    top3Locations.forEach((location) => {
      const locationCounts = Array(24).fill(0);

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const hour = createdAtInIndianTime.getHours();

        if (report.location === location) {
          locationCounts[hour]++;
        }
      });

      countAreaArray.push(locationCounts);
    });
  } else if (week === true) {
    barDataArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    top3Locations.forEach((location) => {
      const locationCounts = Array(7).fill(0); // Array for each day of the week

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const dayOfWeek = createdAtInIndianTime.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        if (report.location === location) {
          locationCounts[dayOfWeek]++;
        }
      });

      countAreaArray.push(locationCounts);
    });
    barTitle = "Days";
  } else {
    barDataArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    top3Locations.forEach((location) => {
      const locationCounts = Array(12).fill(0); // Array for each month of the year

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const month = createdAtInIndianTime.getMonth(); // 0 for January, 1 for February, ..., 11 for December

        if (report.location === location) {
          locationCounts[month]++;
        }
      });

      countAreaArray.push(locationCounts);
    });
    barTitle = "Months";
  }

  countAreaArray = countAreaArray || [];
  console.log("top3Locations", top3Locations);
  const barData = {
    series: top3Locations.map((location, index) => ({
      name: location,
      data: countAreaArray[index] || [],
    })),
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: barDataArray,
        title: {
          text: barTitle,
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
      },
      yaxis: {
        title: {
          text: "Percentage",
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
        tickAmount: 4,
        labels: {
          style: {
            fontSize: "10px", // Adjust the font size of the labels if needed
            fontWeight: "600",
            fontFamily: "PlusJakartaSans", // Adjust the font family as needed
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
          // Adjust the width of the bars
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#F7737B", "#FFA34E", "#5CAFFF"], // Set colors for the bars
    },
  };

  const violationCounts = filteredReports.reduce((counts, report) => {
    const violation = report.violationType;
    counts[violation] = (counts[violation] || 0) + 1;
    return counts;
  }, {});
  const sortedViolations = Object.keys(violationCounts).sort(
    (a, b) => violationCounts[b] - violationCounts[a]
  );
  console.log("top 3 violations", sortedViolations);
  // Get the top 3 violations
  const top3violations = sortedViolations.slice(0, 3);

  // Create an array to store the counts for each location
  let violationCountsArray = [];
  let mutiGraphDataArray = [];
  let multiGraphTitle;
  if (!week && !currentMonth) {
    mutiGraphDataArray = [
      "1hr",
      "2hr",
      "3hr",
      "4hr",
      "5hr",
      "6hr",
      "7hr",
      "8hr",
      "9hr",
      "10hr",
      "11hr",
      "12hr",
      "13hr",
      "14hr",
      "15hr",
      "16hr",
      "17hr",
      "18hr",
      "19hr",
      "20hr",
      "21hr",
      "22hr",
      "23hr",
      "24hr",
    ];

    multiGraphTitle = "Hours";
    top3violations.forEach((violationType) => {
      const violationCounts = Array(24).fill(0);

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const hour = createdAtInIndianTime.getHours();

        if (report.violationType === violationType) {
          violationCounts[hour]++;
        }
      });

      violationCountsArray.push(violationCounts);
      console.log("count", violationCounts);
    });
  } else if (week) {
    mutiGraphDataArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    top3violations.forEach((violationType) => {
      const violationCounts = Array(7).fill(0);

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const dayOfWeek = createdAtInIndianTime.getDay();

        if (report.violationType === violationType) {
          violationCounts[dayOfWeek]++;
        }
      });

      violationCountsArray.push(violationCounts);
    });

    multiGraphTitle = "Days";
  } else {
    mutiGraphDataArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    multiGraphTitle = "Months";

    top3violations.forEach((violationType) => {
      const violationCounts = Array(12).fill(0);

      filteredReports.forEach((report) => {
        const createdAt = new Date(report.createdAt);
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const createdAtInIndianTime = new Date(
          createdAt.getTime() - indianTimeOffset
        );
        const month = createdAtInIndianTime.getMonth();

        if (report.violationType === violationType) {
          violationCounts[month]++;
        }
      });

      violationCountsArray.push(violationCounts);
    });
  }
  violationCountsArray = violationCountsArray || [];
  console.log(violationCountsArray);
  const maximumViolationCount = Math.max(...violationCountsArray.flat());
  const violationData = {
    series: top3violations.map((violation, index) => ({
      name: violation,
      data: violationCountsArray[index], // Include the entire array for the violation
    })),
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "solid",
        colors: [
          "rgba(20, 63, 122, 0)",
          "rgba(255, 87, 51, 0)",
          "rgba(0, 188, 212, 0)",
          "rgba(255, 152, 0, 0)",
        ],
      },
      xaxis: {
        categories: mutiGraphDataArray,
        tickAmount: 24,
        title: {
          text: multiGraphTitle,
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
      },
      yaxis: {
        min: 0,
        max:
          maximumViolationCount <= 5
            ? 5
            : Math.ceil(maximumViolationCount / 5) * 5,
        tickAmount: 5,
        title: {
          text: "Count",
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
        grid: {
          strokeDashArray: 10,
          stroke: "#FFF",
        },
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
        y: {
          formatter: function (val) {
            return val + " Defects";
          },
        },
        enabled: true,
        shared: true,
      },
      legend: {
        show: false,
      },
      colors: ["#077BCE", "#A168BA", "#DE8D9C"], // Line colors
    },
  };

  const circleColors = ["#F7737B", "#FFA34E", "#5CAFFF"];
  const violationColors = ["#077BCE", "#A168BA", "#DE8D9C"];
  const areaAlignClass =
    top3Locations.length === 1
      ? "Area-align-one"
      : top3Locations.length === 2
      ? "Area-align-two"
      : "Area-align";

  const violationAlignClass =
    top3violations.length === 1
      ? "Area-align-one"
      : top3violations.length === 2
      ? "Area-align-two"
      : "Area-align";
  return (
    <div className="analytics-main">
      <div style={{ marginBottom: "100px" }}>
        <div className="filter">
          <div className="dropdown-filter">
            <FormControl
              sx={{
                width: 250,
              }}
            >
              <Select
                className={`analytics-select ${
                  selectedIncident.length !== 0
                    ? "report-violation-dropdown-selected"
                    : ""
                }`}
                multiple
                displayEmpty
                value={selectedIncident}
                onChange={handleViolationChange}
                input={<OutlinedInput />} // Change label to "Location"
                renderValue={(selected) => {
                  if (selectedIncident.length === 0) {
                    return <em className="select-text">Violation</em>;
                  }
                  return (
                    <span className="select-text">{selected.join(", ")}</span>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={ExpandMore}
              >
                {incident.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: 250,
              }}
            >
              <Select
                className={`analytics-select ${
                  selectedArea.length !== 0
                    ? "report-violation-dropdown-selected"
                    : ""
                }`}
                multiple
                displayEmpty
                value={selectedArea}
                onChange={handleAreaChange}
                input={<OutlinedInput />} // Change label to "Location"
                renderValue={(selected) => {
                  if (selectedArea.length === 0) {
                    return <em className="select-text">Location</em>;
                  }
                  return (
                    <span className="select-text">{selected.join(", ")}</span>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={ExpandMore}
              >
                {area.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
              className="analytics-download-calender"
              onClick={handleCalenderToggle}
            >
              <span className="calender-text">{dateRangeText}</span>
              <ExpandMore />
              {calenderOpen && (
                <div className="analytics-calender-div">
                  <DateRange
                    width="25%"
                    editableDateInputs={true}
                    onChange={(item) => {
                      const { startDate } = item.selection;

                      if (week) {
                        // If week is true, automatically set the date range to the current week (Sunday to Saturday)
                        const startOfWeek = new Date(startDate.getTime());
                        startOfWeek.setUTCHours(0, 0, 0, 0);
                        startOfWeek.setUTCDate(
                          startOfWeek.getUTCDate() -
                            ((startOfWeek.getUTCDay() + 7) % 7)
                        ); // Set to the first day of the current week

                        const endOfWeek = new Date(startOfWeek);
                        endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 5); // Set to the end of the week (Saturday)
                        endOfWeek.setUTCHours(23, 59, 59, 999); // Set the time to the end of the day

                        setCalenderDate([
                          {
                            startDate: startOfWeek,
                            endDate: endOfWeek,
                            key: "selection",
                          },
                        ]);
                        setCalenderOpen(false);
                      } else if (currentMonth) {
                        const month = startDate.getMonth();
                        const year = startDate.getFullYear();

                        // Set the start date to the first day of the selected month and year
                        const startOfMonth = new Date(year, month, 1);
                        startOfMonth.setHours(0, 0, 0, 0);

                        // Set the end date to the last day of the selected month and year
                        const endOfMonth = new Date(
                          year,
                          month,
                          new Date(year, month + 1, 0).getDate()
                        );
                        endOfMonth.setHours(23, 59, 59, 999);

                        setCalenderDate([
                          {
                            startDate: startOfMonth,
                            endDate: endOfMonth,
                            key: "selection",
                          },
                        ]);
                        setCalenderOpen(false);
                      } else {
                        // Default case: set the date range to the selected startDate and endDate
                        setCalenderDate([
                          { startDate, endDate: startDate, key: "selection" },
                        ]);
                        setCalenderOpen(false);
                      }
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={calenderDate}
                    endDatePlaceholder={new Date()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dropdown-filter">
            <Button
              variant={activeButton === "Today" ? "primary" : "outline-primary"}
              className="button"
              onClick={handleTodayClick}
            >
              Today
            </Button>
            <Button
              variant={
                activeButton === "Weekly" ? "primary" : "outline-primary"
              }
              className="button"
              onClick={handleWeekClick}
            >
              Weekly
            </Button>
            <Button
              variant={
                activeButton === "Monthly" ? "primary" : "outline-primary"
              }
              className="button"
              onClick={handleMonthClick}
            >
              Monthly
            </Button>
          </div>
        </div>
        <div className="analytics-main-card">
          <div className="analytics-big-card">
            <p className="analytics-header">Incident Count</p>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="area"
              height={190}
            />
          </div>
          <div className="analytics-small-card">
            <p className="analytics-header">Incident Breakdown</p>
            {
              <div className="align-center">
                <div className="pie">
                  <PieChart width={width} height={height}>
                    <Pie
                      data={filteredChartData}
                      cx={width / 2}
                      cy={height / 2}
                      innerRadius={50}
                      outerRadius={62}
                      paddingAngle={5}
                      cornerRadius={15}
                      startAngle={-90}
                      endAngle={360}
                      dataKey="value"
                    ></Pie>
                  </PieChart>
                </div>
                <div className="legend">
                  {filteredChartData.map((item, index) => (
                    <div key={index} className="legend-item">
                      <div
                        className="legend-color"
                        style={{ background: item.fill }}
                      ></div>
                      <div className="legend-label">{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
        <div className="analytics-main-card">
          <div className="analytics-big-card">
            <p className="analytics-header">Unsafe Area Comparison</p>
            <ReactApexChart
              options={barData.options}
              series={barData.series}
              type="bar"
              height={180}
            />
          </div>
          <div className="analytics-small-card">
            <p className="analytics-header">Most Unsafe Area</p>
            <div className={areaAlignClass}>
              {top3Locations.map((item, index) => (
                <div className="area-block" key={index}>
                  <div
                    className="area-circle"
                    style={{ backgroundColor: circleColors[index] }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className="area-text"
                    style={{ color: circleColors[index] }}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="analytics-main-card">
          <div className="analytics-big-card">
            <p className="analytics-header">Frequent Violation Comparison</p>
            <ReactApexChart
              options={violationData.options}
              series={violationData.series}
              type="area"
              height={190}
            />
          </div>
          <div className="analytics-small-card">
            <p className="analytics-header">Most Frequent Violations</p>
            <div className={violationAlignClass}>
              {top3violations.map((item, index) => (
                <div className="area-block" key={index}>
                  <div
                    className="area-circle"
                    style={{ backgroundColor: violationColors[index] }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className="area-text"
                    style={{ color: violationColors[index] }}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredReportsData: state.filtered.data,
  clientLocation: state.clientLocation.locations,
});

const mapDispatchToProps = {
  fetchfilteredReports,
  fetchClientLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);

// import React from "react";
// import { LicenseInfo } from "@mui/x-date-pickers-pro";

// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
// import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import Calendar from "@mui/icons-material/Event";
// // Set your license key for MUI X Date Pickers Pro
// LicenseInfo.setLicenseKey(
//   "x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e"
// );

// const AnalyticsPage = () => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={["SingleInputDateRangeField"]}>
//         <DateRangePicker
//           slots={{ field: SingleInputDateRangeField }}
//           slotProps={{
//             textField: { InputProps: { endAdornment: <Calendar /> } },
//           }}
//         />
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// };

// export default AnalyticsPage;

// <FormControl sx={{ m: 1, width: "25ch" }} size="small">
//   <InputLabel htmlFor="outlined-adornment-password">
//     Password
//   </InputLabel>
//   <OutlinedInput
//     id="outlined-adornment-password"
//     type="text"
//     endAdornment={<InputAdornment position="end"></InputAdornment>}
//     label="Password"
//   />
// </FormControl>;
