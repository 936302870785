import React, { useState } from "react";
import "./Ticket.css";
import { HiOutlineCpuChip } from "react-icons/hi2";
import { PiFolderThin } from "react-icons/pi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchTickets } from "../../../redux/action/ticket";
import { useEffect } from "react";
import SupportComments from "../Comments/Comments";
import { getClientDetailsFromLocalStorage } from "../../../components/LocalStorage";
import SupportFiles from "../Comments/Files/Files";
import { fetchSupportFiles } from "../../../redux/action/ticket";

function Ticket({ tickets, loading, alltickets }) {
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [filesOpen,setFilesOpen]=useState(false);
  const [curClientId, setCurClientId] = useState(null);
  const [curUser, setCurUser] = useState(null);
  const [curUserId, setCurUserId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      setCurClientId(storedClientDetails.client);
      setCurUser(storedClientDetails.name);
      setCurUserId(storedClientDetails._id);
      // Update clientDetails state with the value from local Storage
      dispatch({ type: "SET_CLIENT_DETAILS", payload: storedClientDetails });
      
    }
  }, [dispatch]);
  function openCommentsPopup() {
    setCommentsOpen(!commentsOpen);
    dispatch(fetchSupportFiles(curClientId,alltickets._id));
  }
  function openFilesPopup() {
    setFilesOpen(!filesOpen);
    dispatch(fetchSupportFiles(curClientId,alltickets._id));
  }
  function formatTime(timeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedTime = new Intl.DateTimeFormat("en-IN", options).format(
      new Date(timeString)
    );
    return formattedTime;
  }
  console.log(alltickets.comments);
  return  (
    <>
      {commentsOpen && (
        <SupportComments
          commentsOpen={commentsOpen}
          setCommentsOpen={setCommentsOpen}
          curUser={curUser}
          curUserId={curUserId}
          curClientId={curClientId}
          curTicketId={alltickets._id}
          comments={alltickets.comments}
        />
      )}
      {filesOpen && (
        <SupportFiles
          filesOpen={filesOpen}
          setFilesOpen={setFilesOpen}
          curUser={curUser}
          curUserId={curUserId}
          curClientId={curClientId}
          curTicketId={alltickets._id}
          comments={alltickets.comments}
        />
      )}
      <div className="single-ticket" key={alltickets._id}>
        <div className="ticket-status-container">
          <span className="ticket-number">{alltickets._id.slice(-7)}</span>{" "}
          <div
            className={`status ${
              alltickets.status === "open"
                ? "support-ticket-open"
                : alltickets.status === "resolved"
                ? "support-ticket-resolved"
                : "support-ticket-progress"
            }`}
          >
            {alltickets.status.toUpperCase()}
          </div>
        </div>
        <div className="time-container">{formatTime(alltickets.createdAt)}</div>
        <div className="file-type-container">
          <div className="file-container">
            <HiOutlineCpuChip className="chip" />
            <span>{alltickets.reason}</span>
          </div>
          <div className="file-length-container"  onClick={openFilesPopup}>
          {/* <div className="file-length-container"> */}
            <PiFolderThin className="folder" />
            <span>{alltickets.file.length>0 ? `${alltickets.file.length} Files`:"No Files"}</span>
          </div>
        </div>
        <div className="comments-container"  onClick={openCommentsPopup}>
        {/* <div className="comments-container" > */}
          <IoChatbubblesOutline className="comments" />{" "}
          <span>
            {alltickets.comments && alltickets.comments.length > 0
              ? `${alltickets.comments.length} Comments`
              : "Add Comments"}
          </span>
          <span className="greater-than-container"> &gt;</span>
        </div>
      </div>
    </>
  );
}

export default Ticket;
