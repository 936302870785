import React from 'react'
import LeftNav from '../../components/LeftNav/LeftNav'
import TopBar from '../../components/TopBar/TopBar'
import ReportPage from '.'
import '../dashboard/dashboard.css'
const Report = () => {
  return (
    <div>
    <div className='dashboard-main'>
    <div className='sidebarCss'>
    <LeftNav/></div>
    <div className='mainCss'>
    <TopBar/>
    <ReportPage/>
    </div>
    </div>
    </div>
  )
}

export default Report
