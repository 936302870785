import { server } from "../store";
import axios from "axios";

export const addSupportComment =
  (comment, userId, ticketId, name, fileNames, clientId) =>
  async (dispatch) => {
    try {
      dispatch({ type: "addSupportCommentRequest" });
      const { data } = await axios.post(
        `${server}/ticket/add-comment`,
        { comment, userId, ticketId, name, fileNames, clientId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      dispatch({ type: "addSupportCommentSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "addSupportCommentFailed",
        payload: error?.message,
      });
    }
  };
// export const addSupportCommentFiles = (file,clientId,ticketId,) => async (dispatch) => {
//   try {
//     dispatch({ type: "addSupportCommentRequest" });
//     const { data } = await axios.post(
//       `${server}/ticket/add-file`,
//       {file,clientId,ticketId},
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       }
//     );
//     dispatch({ type: "addSupportCommentSuccess", payload: data });
//   } catch (error) {
//     dispatch({
//       type: "addSupportCommentFailed",
//       payload: error?.message,
//     });
//   }
// };

export const fetchTickets = () => async (dispatch) => {
  try {
    dispatch({ type: "ticketfetchRequest" });
    const { data } = await axios.get(`${server}/ticket/tickets`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "ticketfetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "ticketfetchFailed",
      payload: error?.message,
    });
  }
};
export const addNewTicket =
  (client, reason, call, subject, status, fileNames, files) =>
  async (dispatch) => {
    try {
      dispatch({ type: "addTicketRequest" });

      // First request to add the ticket
      const {
        data: { ticket },
      } = await axios.post(
        `${server}/ticket/add-ticket`,
        { client, reason, call, subject, status, fileNames, files },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // Extract ticket ID from the response
      const ticketId = ticket._id;

      // Second request to add files if files are present
      if (files && files.length > 0) {
        const formData = new FormData();
        files.forEach((singleFile, index) => {
          formData.append(`file`, singleFile);
        });
        formData.append("clientId", client);
        formData.append("ticketId", ticketId); // Pass the ticket ID to the second request

        // Send the second request to add files
        await axios.post(
          `${server}/ticket/add-file`, // Adjust the endpoint as needed
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      dispatch({ type: "addTicketSuccess", payload: ticket });
    } catch (error) {
      dispatch({
        type: "addTicketFailed",
        payload: error?.message,
      });
    }
  };
export const addSupportCommentFiles =
  (file, clientId, ticketId) => async (dispatch) => {
    try {
      dispatch({ type: "addSupportCommentRequest" });
      const formData = new FormData();
      file.forEach((singleFile, index) => {
        formData.append(`file`, singleFile);
      });
      formData.append("clientId", clientId);
      formData.append("ticketId", ticketId);
      // console.log(formData)
      const { data } = await axios.post(`${server}/ticket/add-file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      dispatch({ type: "addSupportCommentSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "addSupportCommentFailed",
        payload: error?.message,
      });
    }
  };
export const addSupportFiles =
  (ticketId, clientId, fileNames) => async (dispatch) => {
    try {
      dispatch({ type: "addSupportCommentRequest" });
      const { data } = await axios.post(
        `${server}/ticket/add-ticket-file`,
        { ticketId, clientId, fileNames },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      dispatch({ type: "addSupportCommentSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "addSupportCommentFailed",
        payload: error?.message,
      });
    }
  };

export const fetchSupportFiles = (clientId, ticketId) => async (dispatch) => {
  try {
    dispatch({ type: "filefetchRequest" });
    const { data } = await axios.get(`${server}/ticket/fetch-files`, {
      params: {
        clientId: clientId,
        ticketId: ticketId,
      },
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    // console.log(data);
    dispatch({ type: "filefetchSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "filefetchFailed",
      payload: error?.message,
    });
  }
};
export const downloadSupportFile =
  (clientId, ticketId, fileName) => async (dispatch) => {
    try {
      dispatch({ type: "fileDownloadRequest" });
      const { data } = await axios.post(
        `${server}/ticket/download-file`,
        { clientId, ticketId, fileName },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      dispatch({ type: "fileDownloadSuccess", payload: data });
    } catch (error) {
      dispatch({
        type: "fileDownloadFailed",
        payload: error?.message,
      });
    }
  };
