import React from 'react'
import LeftNav from '../../components/LeftNav/LeftNav'
import TopBar from '../../components/TopBar/TopBar'
import ProfilePage from './ProfilePage'

function Profile() {
  return (
    <div className="dashboard-main">
      <div className="sidebarCss">
        <LeftNav />
      </div>
      <div className="mainCss">
        <TopBar />
        <ProfilePage />
      </div>
    </div>
  );
}

export default Profile

