import { createReducer } from "@reduxjs/toolkit";

const userReducer = createReducer(
  {},
  {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.userDetails = action.payload.user;
      state.message = action.payload.message;
      state.success=action.payload.success;
    },
    loginFailed: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
      state.success=false;
    },
    clearError: (state) => {
      state.error = false;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    logoutRequest: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      // state.userDetails = action.payload.user;
      state.message = action.payload.message;
    },
    logoutFailed: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    allUserstRequest: (state) => {
      state.loading = true;
    },
    allUsersSuccess: (state, action) => {
      state.loading = false;
      state.allUsers=action.payload.users;
      // state.userDetails = action.payload.user;
      state.message = action.payload.message;
    },
    allUsersFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editUsertRequest: (state) => {
      state.loading = true;
    },
    editUserSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    editUserFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteUsertRequest: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    deleteUserFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
);

export default userReducer;