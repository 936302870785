import React, { useState } from "react";
import Select from "react-select";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import "./EmailPopup.css";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { MdAttachFile } from "react-icons/md";
import upload from "../components/images/upload.svg";
import uploadedfiles from "../components/images/uploadedfiles.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdClose } from "react-icons/md";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const EmailPopup = ({ anchor, dummyReport, setOpenEmail }) => {
  const allUsers = useSelector((state) => state.user.allUsers);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [assignCurrent, setAssignCurrent] = useState({
    label: dummyReport.assigned,
    id: 0,
  });

  const [fileData, setFileData] = useState([]);
  const [ticketUploadFiles, setTicketUploadFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const toggleDrawerEmail = (anchor, openEmail) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEmail(openEmail);
    setState({ ...state, [anchor]: openEmail });
    setAssignCurrent(null);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`,
    };
  }
  const assign =
    (allUsers || []).map((user) => ({
      label: user.name,
      id: user._id,
      mail: user.email,
    })) || [];

  const colourOptions = [
    { value: "deep@quantictech.ai", label: "Deep" },
    { value: "ilakya@quantictech.ai", label: "Ilakya" },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "270px",
      borderWidth: "0px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: "0px", // Set width to 0px
    }),
  };

  const getOptionLabel = (option) => option.label;

  const handleFileChange = (event) => {
    const files = event.target.files;
    const filesData = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setFileData(filesData);
    setTicketUploadFiles(Array.from(files));

    const updatedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = function (e) {
        updatedFiles.push({
          dataUrl: e.target.result,
          fileName: files[i].name,
        });
        if (updatedFiles.length === files.length) {
          setUploadFiles(updatedFiles);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  function formatFileSize(sizeInBytes) {
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;

    if (sizeInBytes >= GB) {
      return `${(sizeInBytes / GB).toFixed(2)} GB`;
    } else if (sizeInBytes >= MB) {
      return `${(sizeInBytes / MB).toFixed(2)} MB`;
    } else if (sizeInBytes >= KB) {
      return `${(sizeInBytes / KB).toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }

  console.log("file names", fileData);
  const handleRemoveFile = (indexToRemove) => {
    setUploadFiles(uploadFiles.filter((_, index) => index !== indexToRemove));
    setTicketUploadFiles(
      ticketUploadFiles.filter((_, index) => index !== indexToRemove)
    );
    setFileData(fileData.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Box
      className="report-edit-popup-container"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 340,
      }}
      role="presentation"
    >
      <div className="report-edit-popup-title-container">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Typography
            style={{
              marginLeft: "11px",
              fontFamily: "PlusJakartaSans",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              margin: "16px",
            }}
          >
            Email
          </Typography>
          <IconButton onClick={toggleDrawerEmail(anchor, false)}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>
      </div>
      <div
        style={{
          margin: "14px",
          backgroundColor: "#F9FAFB",
          height: "140px",
          borderRadius: "0.5rem",
        }}
      >
        <Stack
          style={{ marginLeft: "10px", marginTop: "16px" }}
          flexDirection={"row"}
        >
          <img
            src={dummyReport.imagepath}
            alt="Report Img"
            width="100"
            height="55"
            style={{ marginTop: "4px" }}
          />
          <div>
            <div style={{ marginLeft: "12px", marginTop: "4px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                }}
              >
                #{dummyReport._id.slice(-4)}
              </Typography>
            </div>
            <div style={{ marginLeft: "12px", marginTop: "6px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
              >
                {moment.utc(dummyReport.createdAt).format("DD MMM, hh:mm:ss")}
              </Typography>
            </div>
          </div>
        </Stack>
        <Divider style={{ marginTop: "16px" }} />
        <Stack
          direction={"row"}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"column"}>
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Location
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.location}
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Incident
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.violationType}
            </Typography>
          </Stack>
        </Stack>
        <div className="email-content-footer-container">
          <div className="email-content">
            <div className="email-body">
              <div className="email-to-container">
                <label className="para">To</label>
                <Select
                  isMulti
                  name="colors"
                  width="270px"
                  options={colourOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  hideSelectedOptions={true}
                  getOptionLabel={getOptionLabel}
                  styles={customStyles}
                  placeholder=""
                />
              </div>

              <div className="email-subject-container">
                <label className="para">Subject</label>
                <input
                  type="text"
                  className="email-input-field"
                  placeholder=""
                />
              </div>
              <div className="email-body-container">
                <textarea
                  className="email-body-textarea"
                  placeholder="Body"
                  style={{ width: "100%", height: "250px" }} // Adjust width and height as needed
                ></textarea>
              </div>
              <div className="email-file-upload">
                <label for="fileInput" className="report-upload-files">
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                  <img src={upload} alt="upload" width={25} />
                </label>
              </div>
              <div className="upload-files">
                {fileData.map((file, index) => (
                  <div key={index} className="email-file">
                    <div className="file-info">
                      <img src={uploadedfiles} alt="file" width={25} />
                      <p>{`${file.name} (${formatFileSize(file.size)})`}</p>
                    </div>

                    <div onClick={() => handleRemoveFile(index)}>
                      <MdClose />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="report-edit-popup-footer">
            <Button
              variant="contained"
              style={{
                marginLeft: "230px",
                marginTop: "16px",
                backgroundColor: "#4040F2",
                fontFamily: "PlusJakartaSans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default EmailPopup;
