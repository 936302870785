import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Example asynchronous action using createAsyncThunk
export const fetchImage = createAsyncThunk('image/fetchImage', async (imageUrl) => {
  // Perform asynchronous operation, e.g., fetching data from an API
  const response = await fetch(imageUrl);
  const data = await response.json();
  return data;
});

const initialState = {
  expandImgUrl: null,
  status: 'idle',
  error: null,
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setExpandImgUrl: (state, action) => {
      state.expandImgUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchImage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the fetched data if needed
        // For example, you might update a property with the fetched data
        // state.someData = action.payload;
      })
      .addCase(fetchImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setExpandImgUrl } = imageSlice.actions;
export const selectExpandImgUrl = (state) => state.image.expandImgUrl;
export const selectImageStatus = (state) => state.image.status;
export const selectImageError = (state) => state.image.error;

export default imageSlice.reducer;