import { server } from "../store";
import axios from "axios";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: "loginRequest" });
    const { data } = await axios.post(
      `${server}/user/login`,
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "loginSuccess", payload: data });
    return true;
  } catch (error) {
    dispatch({ type: "loginFailed", payload: error.response.data.message });
    return false;
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: "logoutRequest" });
    const { data } = await axios.post(`${server}/user/logout`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({ type: "logoutSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "logoutFailed",
      payload: error?.message,
    });
  }
};
export const fetchAllUsers = (client) => async (dispatch) => {
  try {
    dispatch({ type: "allUsersRequest" });
    const { data } = await axios.get(`${server}/user/all-user`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        client,
      },
      withCredentials: true,
    });
    dispatch({ type: "allUsersSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "allUsersFailed",
      payload: error?.message,
    });
  }
};
export const editUser = (id, name, phoneNumber, email) => async (dispatch) => {
  try {
    dispatch({ type: "editUserRequest" });
    const { data } = await axios.post(
      `${server}/user/edit-user`,
      { id, name, phoneNumber, email },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "editUserSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "editUserFailed",
      payload: error?.message,
    });
  }
};
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteUserRequest" });
    const { data } = await axios.post(
      `${server}/user/delete-user`,
      { id },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "deleteUserSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "deleteUserFailed",
      payload: error?.message,
    });
  }
};
// export const allUsers = () => async (dispatch) => {
//   try {
//     dispatch({ type: "allUsersRequest" });
//     const { data } = await axios.get(
//       `${server}/user/all-user`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       }
//     );
//     console.log(data);
//     dispatch({ type: "allUsersSuccess", payload: data });
//   } catch (error) {
//     dispatch({ type: "allUsersFailed", payload: error.response.data.message });
//   }
// };
