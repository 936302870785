import {
    fetchfilteredReportDataRequest,
    fetchfilteredReportDataSuccess,
    fetchfilteredReportDataFailure
  } from '../action/report';

const recentReport = {
    data: [],
    loading: false,
    error: null,
  };
  
  
  const filteredReportReducer = (state = recentReport, action) => {
    switch (action.type) {
      case fetchfilteredReportDataRequest().type:
        return { ...state, loading: true, error: null };
      case fetchfilteredReportDataSuccess().type:
        return { ...state, loading: false, data: action.payload };
      case fetchfilteredReportDataFailure().type:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default filteredReportReducer;