import React, { useEffect, useMemo, useState } from "react";
import "./Files.css";
import { MdClose } from "react-icons/md";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../../../redux/store";
import {
  addSupportComment,
  addSupportCommentFiles,
  addSupportFiles,
  downloadSupportFile,
  fetchSupportFiles,
  fetchTickets,
} from "../../../../redux/action/ticket";
import { HiDownload } from "react-icons/hi";
import dummyPdfPreview from "../../Images/dummyPdfPreview.png";
import dummyDocPreview from "../../Images/dummyDocumentPreview.png";
function SupportFiles({
  filesOpen,
  setFilesOpen,
  curClientId,
  curUser,
  curUserId,
  curTicketId,
  comments,
}) {
  const dispatch = useDispatch();
  const [supportComment, setSupportComment] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [ticketUploadFiles, setTicketUploadFiles] = useState(null);
  const [fileNames, setFileNames] = useState(null);
  const [uploadFilesOpen, setUploadFilesOpen] = useState(false);
  const files = useSelector((state) => state.ticket.allfiles);
  console.log(uploadFiles);
  console.log(files);
  const handleCloseComments = () => {
    setFilesOpen(!filesOpen);
  };
  // const memoizedProps = useMemo(() => [curClientId, curTicketId], [curClientId, curTicketId]);
  // useEffect(()=>{
  //   dispatch(fetchSupportFiles(curClientId,curTicketId));
  // },[memoizedProps])
  const handleCloseFiles = () => {
    setFilesOpen(!filesOpen);
  };

  console.log(curUser);

  // const handleFileChange = async (event) => {
  //   const files = event.target.files;
  //   if (files.length === 0) {
  //     // Do nothing if no files are selected
  //     return;
  //   }

  //   // Limit the total number of files to 2
  //   const filesToUpload = Array.from(files).slice(0, 2);

  //   const fileNames = filesToUpload.map((file) => file.name);
  //   setFileNames(fileNames);

  //   const formData = new FormData();
  //   filesToUpload.forEach((file) => {
  //     formData.append("file", file);
  //   });

  //   // Set the last two files as the new files in ticketUploadFiles
  //   setTicketUploadFiles(filesToUpload);

  //   // Read the selected files and update uploadFiles state
  //   const updatedImages = [];
  //   for (let i = 0; i < Math.min(filesToUpload.length, 2); i++) {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       updatedImages.push(e.target.result);
  //       if (updatedImages.length === Math.min(filesToUpload.length, 2)) {
  //         setUploadFiles(updatedImages);
  //       }
  //     };
  //     reader.readAsDataURL(filesToUpload[i]);
  //   }
  // };

  // const uploadButtonHandler = async () => {
  //   setUploadFilesOpen(!uploadFilesOpen);
  // };
  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      // Do nothing if no files are selected
      return;
    }

    // Limit the total number of files to 2
    const filesToUpload = Array.from(files).slice(0, 2);

    const fileNames = filesToUpload.map((file) => file.name);
    setFileNames(fileNames);

    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("file", file);
    });

    // Store data URLs and file names in uploadFiles state
    const updatedFiles = [];
    for (let i = 0; i < Math.min(filesToUpload.length, 2); i++) {
      const reader = new FileReader();
      reader.onload = function (e) {
        updatedFiles.push({
          dataUrl: e.target.result,
          fileName: filesToUpload[i].name,
        });
        if (updatedFiles.length === Math.min(filesToUpload.length, 2)) {
          setUploadFiles(updatedFiles);
        }
      };
      reader.readAsDataURL(filesToUpload[i]);
    }

    // Set the last two files as the new files in ticketUploadFiles
    setTicketUploadFiles(filesToUpload);
  };
  const saveButtonHandler = async () => {
    try {
      if (
        ((fileNames !== null) !== "" || ticketUploadFiles !== null) &&
        curClientId !== null &&
        curTicketId !== ""
      ) {
        const newFilesToUpload = ticketUploadFiles.filter(
          (file) => !files.includes(file.name)
        );
        await dispatch(
          addSupportFiles(
            curTicketId,
            curClientId,
            newFilesToUpload.map((file) => file.name)
          )
        );
        console.log(fileNames);

        console.log("new files", newFilesToUpload);
        if (ticketUploadFiles && ticketUploadFiles.length > 0) {
          await dispatch(
            addSupportCommentFiles(newFilesToUpload, curClientId, curTicketId) // addSupporCommentFiles uplaods the file to aws
          );
        }

        await dispatch(fetchTickets());

        setSupportComment("");
        setUploadFiles([]);
        setFilesOpen(false);
        setTicketUploadFiles(null);
      }
      setUploadFilesOpen(false);
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };
  const handleRemoveFile = (indexToRemove) => {
    setUploadFiles(uploadFiles.filter((_, index) => index !== indexToRemove));
    setTicketUploadFiles(
      ticketUploadFiles.filter((_, index) => index !== indexToRemove)
    );
  };
  //   const handleFileDownload = (fileName) => {
  //     dispatch(downloadSupportFile(curClientId,curTicketId,fileName));
  // };
  console.log(
    "uploadFiles",
    uploadFiles,
    "ticketUploadFiles",
    ticketUploadFiles
  );
  const handleFileDownload = async (fileName) => {
    try {
      const response = await axios.post(
        `${server}/ticket/download-file`,
        { clientId: curClientId, ticketId: curTicketId, fileName: fileName },
        {
          responseType: "blob", // Set the response type to blob
          withCredentials: true,
        }
      );

      // Create a temporary anchor element
      const anchorElement = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Set the anchor's href and download attributes
      anchorElement.href = url;
      anchorElement.download = fileName;

      // Trigger a click event on the anchor element to start the download
      anchorElement.click();

      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error
    }
  };
  return (
    <div className="download-container">
      <div className="download-header">
        <span>{`ID:#${curTicketId.slice(-7)}`}</span>
        <MdClose className="popup-close-button" onClick={handleCloseComments} />
      </div>
      <div className="support-margin-container">
        <div className="support-comments-content">
          {files &&
            files.map((fileName, index) => (
              <div
                className={`support-each-file-container ${
                  fileName.includes(".pdf") ||
                  fileName.includes(".doc") ||
                  fileName.includes(".docx")
                    ? "pdf-doc-container"
                    : ""
                }`}
                key={index}
              >
                <img
                  className={`supports-file-preview-image ${
                    fileName.includes(".pdf") ||
                    fileName.includes(".doc") ||
                    fileName.includes(".docx")
                      ? "pdf-doc-preview-image"
                      : ""
                  }`}
                  src={
                    fileName.includes(".pdf")
                      ? dummyPdfPreview
                      : fileName.includes(".doc") || fileName.includes(".docx")
                      ? dummyDocPreview
                      : `https://cv-safety.s3.ap-south-1.amazonaws.com/uploads/${curClientId}/${curTicketId}/${fileName}`
                  }
                  alt={fileName}
                />
                <span className="preview-filename">{fileName}</span>
                {(fileName.includes(".pdf") ||
                  fileName.includes(".doc") ||
                  fileName.includes(".docx") ||
                  fileName.includes(".ppt")) && (
                  <button
                    className="support-files-download-button"
                    onClick={() => handleFileDownload(fileName)}
                  >
                    <HiDownload className="support-files-download-icon" />
                  </button>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="comments-footer-container">
        <div className={"upload-files-container-uploader"}>
          {uploadFiles.map((fileData, index) => {
            const { dataUrl, fileName } = fileData;
            let backgroundImageUrl;
            const mimeType = dataUrl.split(",")[0].split(":")[1].split(";")[0];
            if (mimeType === "application/pdf") {
              backgroundImageUrl = `url(${dummyPdfPreview})`; // Set background to dummyPdf for PDF files
            } else if (
              mimeType === "application/msword" ||
              mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              mimeType === "application/wps-office.doc"
            ) {
              backgroundImageUrl = `url(${dummyDocPreview})`; // Set background to dummyDoc for DOC/DOCX files
            } else {
              backgroundImageUrl = `url(${dataUrl})`; // Use the data URL for other file types
            }

            return (
              <div
                className="uploaded-image"
                key={index}
                style={{
                  backgroundImage: backgroundImageUrl,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className="uploaded-image-close"
                  onClick={() => handleRemoveFile(index)}
                >
                  <MdClose />
                </div>
                <span className="file-preview-title">{fileName}</span>{" "}
                {/* Display the file name */}
              </div>
            );
          })}
        </div>
        <div className="upload-files-container support-files-upload-container">
          {/* Render uploaded Files */}

          <label
            for="fileInput"
            class="ticket-upload-btn support-ticket-upload-btn"
          >
            <input
              type="file"
              id="fileInput"
              accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <div class="square-box">
              <span>Upload</span>
              {/* <div class="plus-icon">+</div> */}
            </div>
          </label>
        </div>
        <div className="comments-footer">
          <div className="support-footer-btn-container">
            <button className="download-cancel-btn" onClick={handleCloseFiles}>
              Cancel
            </button>
            <button
              className="download-download-btn"
              onClick={saveButtonHandler}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportFiles;
